import {combineReducers} from "redux";
import mapReducer from "../map/map-reducer";
import projectsReducer from "./projectsReducer";
import showInfoReducer from "../showInfo/showInfoReducer";
import TimeLine from "./TimeLine";
import drawReducer from "../draw/draw-reducer";
import entityReducer from "../entity/entity-reducer";
import roadRouteCheckReducer from "../road-route-check/road-route-check-reducer";
import {customizedKeplerGlReducer} from "../kepler/kepler-reducer";


export const rootReducer = combineReducers({
  map: mapReducer,
  draw: drawReducer,
  projects: projectsReducer,
  showInfo: showInfoReducer,
  entity: entityReducer,
  roadRouteCheck: roadRouteCheckReducer,
  TimeLine,
  keplerGl: customizedKeplerGlReducer
});

export type RootState = ReturnType<typeof rootReducer>;
