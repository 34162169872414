import React from 'react';
import EntityListRowForm from "../../../../components/Entities/EntityListRowForm";
import {EntityServiceName} from "../../../../api/enums/enums";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";


const RoadsList = () => {
    const entityName = 'replancity_RoadInfrastructureRoadName';
    const layerToReloadEntityName = 'replancity_RoadInfrastructureSegment'; // segments should be deleted on road deletion
    const serviceName = EntityServiceName.ROAD_INFRASTRUCTURE;

    //TODO navigate after road deletion

    return (
        <EntitiesLoader
            entityName={entityName}
            layerEntityName={layerToReloadEntityName}
            serviceName={serviceName}
            form={EntityListRowForm}
        />
    )
}

export default RoadsList;