import {AnyLayer, Layer, AnyLayout, AnyPaint, Visibility, MapboxGeoJSONFeature} from "mapbox-gl"
import {DeckLayerType, MapboxLayerType} from "../../api/enums/enums"


//TODO Check "type" type
export type SourceItem = {
  id: string
  name: string
  entityName: string
  type: MapboxLayerType | DeckLayerType
  visibility: Visibility | undefined;
  order: number;
  opacity: number;
  dayTimeDependent: boolean;
  calculationState?: string;
  cluster: boolean;
  clusterMaxZoom?: number;
  clusterRadius?: number;
  clickable: boolean;
  // showLabels: boolean;
  labelFieldName?: string;
  queryable?: boolean; // to distinguish interactive and uninteractive layers (like "project border")
  extrudable: boolean;
  updateOnFeatureSelection?: boolean;
  isReloadRequired?: boolean;
  showDirection?: boolean;
  group: string;
  zoomDependent: boolean;
  hasBalloon: boolean;
}

export type DateType = {
  name: string;
  fromTime: string;
  toTime: string;
}

export type DataById<T = unknown> = {
  [key: string]: T
}

export type Entity<T> = {
  byId: DataById<T>,
  allId: string[]
}

export type NamedLayer = {
  layerName: string
  layout: AnyLayout
  paint: AnyPaint
  source: string,
  layerType: LayerType,
  order: number,
  loading: boolean,
  isClusterLayer: boolean;
  clickable: boolean;
} & AnyLayer & Layer

export type DeckLayer = Omit<NamedLayer, 'type'> & {
  type: string;
}

export type LayerTypes =
  | NamedLayer
  | DeckLayer

export type Sources = Entity<SourceItem>

export type MapboxGlLayersType = Entity<NamedLayer>

export type DeckGlLayersType = Entity<DeckLayer>

export enum LayerType {
  MAPBOXGL = 'mapboxglLayers',
  DECKGL = 'deckglLayers'
}

export enum LayerVisibility {
  VISIBLE = 'visible',
  NONE = 'none'
}

export interface CoordinatesType {
  longitude: number;
  latitude: number;
}

//TODO probably move to API interfaces
export interface FeatureProperties {
  featureStateId: number | string;
  layerId: string;
  id: string;
  entityName: string;
  color: string;
  width: string;
  highway: string;
  instanceName?: string;
}

//TODO probably move to API interfaces
export type MapboxGeoJSONFeatureWithProperties = MapboxGeoJSONFeature & {
  properties: FeatureProperties
}

export const enum FeatureState {
  HIGHLIGHTED = 'highlighted',
  HOVERED = 'hover',
}

export type MapFiltersType = {
  modes: string[];
  lanesNumMin: number | null;
  lanesNumMax: number | null;
  highwayTypes: string[];
}

export type LngLat = { lng: number; lat: number; }