import React, {memo, useEffect, useMemo, useState} from "react";
import {Form, Row} from "../../form/Form/Form";
import * as SelectBox from "../../form/SelectBox/SelectBox";
import * as SliderField from "../../form/SliderField/SliderField";
import {ProjectRunProcessView} from "../../../api/entities/replancity_Project";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../../utils/utils";
import {Option} from "../../form/SelectBox/SelectBox";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../../LoadingComponent/LoadingComponent";
import * as TextField from "../../form/TextField/TextField";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../../redux/selectors/selectors";
import {LoadingBackdrop} from "../../LoadingBackdrop/LoadingBackdrop";
import {useToastContext} from "../../../context/toastContext";


type Props = {
    startSimulationFn: (data: any) => Promise<any>;
    getSimulationOptionsFn: () => Promise<ProjectRunProcessView>;
    onSimulationFinishFn?: () => void;
}

const StopTypeOptions: Option[] = [
    {
        value: 'DYNAMIC',
        caption: 'DYNAMIC'
    },
    {
        value: 'FIXED',
        caption: 'FIXED'
    }
];

type SimulationSettingsFormNames = keyof Pick<
    ProjectRunProcessView,
    'configurations' |
    'maasPreset' |
    'populationPreset' |
    'publicTransitPreset' |
    'roadInfrastructurePreset' |
    'evPreset' |
    'scenarioCasePreset'
>;

type SettingsType = Record<SimulationSettingsFormNames, string>;

const DEFAULT_SETTINGS: SettingsType = {
    configurations: '',
    maasPreset: '',
    populationPreset: '',
    publicTransitPreset: '',
    roadInfrastructurePreset: '',
    evPreset: '',
    scenarioCasePreset: ''
};

const SimulationSettings = memo(({startSimulationFn, getSimulationOptionsFn, onSimulationFinishFn}: Props) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const [settings, setSettings] = useState<ProjectRunProcessView>();
    const [defaultSettings, setDefaultSettings] = useState<SettingsType>(DEFAULT_SETTINGS);
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    const options: { [key in SimulationSettingsFormNames]: Option[] } = useMemo(() => {
        const tmpDefaultSettings = {...DEFAULT_SETTINGS};

        const options = Object.keys(DEFAULT_SETTINGS).reduce((acc, key) => {
            if (Array.isArray(settings?.[key])) {
                acc[key] = settings?.[key].map(({id, name, defaultPreset = false}, idx) => {
                    if (!idx) {
                        tmpDefaultSettings[key] = id;
                    }

                    if (tmpDefaultSettings[key] && defaultPreset) {
                        tmpDefaultSettings[key] = id;
                    }

                    return {value: id, caption: name};
                });
            }
            return acc;
        }, {} as any);

        setDefaultSettings(tmpDefaultSettings);

        return options;
    }, [settings]);

    useEffect(() => {
        (async function () {
            const resp = await getSimulationOptionsFn();
            if (!isErrorResponse(resp)) {
                setSettings(resp);
            }
        }());
    }, []);

    const onFormSubmit = async (event) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);

        const {
            name,
            roadPreset,
            publicTransit,
            config,
            maasPreset,
            evPreset,
            scenarioCasePreset,
            maxIteration,
            population,
            quality,
            relaxationStartOnPercentage,
            stopType
        } = formDataObj;

        const payload = {
            "task": {
                name,
                ...(roadPreset ? {
                    "roadPreset": {
                        "id": roadPreset
                    }
                } : {}),
                ...(publicTransit ? {
                    "publicTransit": {
                        "id": publicTransit
                    }
                } : {}),
                ...(maasPreset ? {
                    "maasPreset": {
                        "id": maasPreset
                    }
                } : {}),
                ...(evPreset ? {
                    "evPreset": {
                        "id": evPreset
                    }
                } : {}),
                ...(scenarioCasePreset ? {
                    "scenarioCasePreset": {
                        "id": scenarioCasePreset
                    }
                } : {}),
                "population": {
                    "id": population
                },
                "config": {
                    "id": config
                },
                quality,
                stopType,
                maxIteration,
                relaxationStartOnPercentage,
                "project": {
                    "id": projectId
                }
            }
        }

        // if (!areAllRequiredFormFieldsFilled(formDataObj, filteredMetaData)) {
        //     // addToast(`${t('common.not-all-required-fields-are-filled')}`, 'error');
        //     return;
        // }

        setShowSpinner(true);
        const resp = await startSimulationFn(payload);
        setShowSpinner(false);

        if (isErrorResponse(resp)) {
            const {error, details} = resp as any;
            addToast(`${error}: ${details}`, 'error', false);
        } else {
            onSimulationFinishFn?.();
        }
    }

    return (
        <LoadingComponent isLoading={!Object.entries(options).length}>
            <LoadingBackdrop isVisible={showSpinner} transparent>
                <Form
                    name="projectSimulationForm"
                    onSubmit={onFormSubmit}
                    submitBtnTitle={t('simulation.simulation')}
                    testId="projectSimulationForm"
                    submitBtnTestId="projectSimulationFormSubmitBtn"
                >
                    <Row>
                        <TextField.Labeled
                            label={t('simulation.settings-label-name')}
                            name="name"
                            type="text"
                            testId="projectSimulationFormNameInput"
                            required
                            autoFocus
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-road-preset')}
                            name='roadPreset'
                            value={defaultSettings.roadInfrastructurePreset}
                            options={options.roadInfrastructurePreset}
                            required
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-pt-preset')}
                            name='publicTransit'
                            value={defaultSettings.publicTransitPreset}
                            options={options.publicTransitPreset}
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-maas-preset')}
                            name='maasPreset'
                            value={defaultSettings.maasPreset}
                            options={options.maasPreset}
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-ev-preset')}
                            name='evPreset'
                            value={defaultSettings.evPreset}
                            options={options.evPreset}
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-scenario-case-preset')}
                            name='scenarioCasePreset'
                            value={defaultSettings.scenarioCasePreset}
                            options={options.scenarioCasePreset}
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-population')}
                            name='population'
                            value={defaultSettings.populationPreset}
                            options={options.populationPreset}
                            required
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-config')}
                            name='config'
                            value={defaultSettings.configurations}
                            options={options.configurations}
                            required
                        />
                    </Row>
                    <Row>
                        <SliderField.Labeled
                            label={t('simulation.settings-quality')}
                            name="quality"
                            min={0}
                            max={1}
                            step={0.1}
                            defaultValue={1}
                            required
                        />
                    </Row>
                    <Row>
                        <SelectBox.Labeled
                            label={t('simulation.settings-stop-type')}
                            name='stopType'
                            options={StopTypeOptions}
                            required
                        />
                    </Row>
                    <Row>
                        <TextField.Labeled
                            label={t('simulation.settings-max-iteration')}
                            name="maxIteration"
                            type="number"
                            min={1}
                            max={1000}
                            value={200}
                            testId="projectSimulationFormMaxIterationInput"
                            required
                        />
                    </Row>
                    <Row>
                        <TextField.Labeled
                            label={t('simulation.settings-relaxation-start-on-percentage')}
                            name="relaxationStartOnPercentage"
                            type="number"
                            min={50}
                            max={100}
                            value={90}
                            required
                        />
                    </Row>
                </Form>
            </LoadingBackdrop>
        </LoadingComponent>
    )
})

export default SimulationSettings;