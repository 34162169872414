import React from "react";
import {Navigate, Route} from "react-router-dom";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";
import BreadcrumbedComponent from "../../components/BreadcrumbedComponent/BreadcrumbedComponent";
import PopulationEventsList from "./Manager/PopulationEventsList";
import PopulationEventEdit from "./Manager/PopulationEventEdit";
import NetworkEventsList from "./Manager/NetworkEventsList";
import TransitEventsList from "./Manager/TransitEventsList";
import NetworkEventEdit from "./Manager/NetworkEventEdit";
import TransitEventEdit from "./Manager/TransitEventEdit";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const ScenarioPresetsModePage = React.lazy(() => import('./ScenarioPresetsModePage'));

export const useScenarioPresetsRoutes = () => {
    return (
        <Route path="scenario" element={<LazyLoadingWrapper element={<ScenarioPresetsModePage/>}/>}>
            <Route index element={<Navigate to="population" replace/>}/>
            <Route
                element={<BreadcrumbedComponent/>}
                handle={{crumb: (data: any) => ({pathnamePostFix: 'population', name: 'Events', ...data})}}
            >
                <Route path="population">
                    <Route index element={<PopulationEventsList/>}/>
                    <Route path="new" element={<PopulationEventEdit/>}/>
                    <Route
                        path=":populationEventId/edit"
                        element={<PopulationEventEdit/>}
                        loader={({params}) => params}
                        handle={{crumb: ({populationEventId}) => ({id: populationEventId})}}
                    />
                </Route>
            </Route>
            <Route
                element={<BreadcrumbedComponent/>}
                handle={{crumb: (data: any) => ({pathnamePostFix: 'network', name: 'Events', ...data})}}
            >
                <Route path="network" element={<NetworkEventsList/>}/>
                <Route
                    path="network/new"
                    element={<NetworkEventEdit/>}
                    loader={({params}) => params}
                    handle={{crumb: ({networkEventId}) => ({id: networkEventId})}}
                />
                <Route
                    path="network/:networkEventId/edit"
                    element={<NetworkEventEdit/>}
                    loader={({params}) => params}
                    handle={{crumb: ({networkEventId}) => ({id: networkEventId})}}
                />
                <Route path="transit" element={<TransitEventsList/>}/>
                <Route
                    path="transit/new"
                    element={<TransitEventEdit/>}
                    loader={({params}) => params}
                    handle={{crumb: ({networkEventId}) => ({id: networkEventId})}}
                />
                <Route
                    path="transit/:transitEventId/edit"
                    element={<TransitEventEdit/>}
                    loader={({params}) => params}
                    handle={{crumb: ({transitEventId}) => ({id: transitEventId})}}
                />
                {/*</Route>*/}
            </Route>
            <Route
                path='layers'
                element={
                    <LayersListWrapper>
                        <LayersTimeSelectors/>
                    </LayersListWrapper>
                }
            />
        </Route>
    )
}