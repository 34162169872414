import React from "react";
import {ButtonProps} from "./types";
import "./buttons.scss";
import classNames from "classnames";


const Button = ({
                    img = null,
                    text,
                    type,
                    colorType = 'dark',
                    onClick,
                    disabled,
                    width,
                    borderless,
                    testId
                }: ButtonProps) => {
    const className = classNames(
        colorType === 'light' && 'btn-light',
        colorType === 'dark' && 'btn-dark',
        colorType === 'transparent' && 'btn-transparent',
        'btn-medium',
        borderless && 'borderless'
    )

    return (
        <button
            style={{width: width}}
            type={type ?? "button"}
            onClick={onClick}
            disabled={disabled}
            className={className}
            {...(testId ? {'data-testid': testId} : {})}
        >
            <div className="btn">
                {img && img}
                <span>{text && text}</span>
            </div>
        </button>
    );
};
export default Button;
