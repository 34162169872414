import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import {Form, Row} from "../form/Form/Form";
import {useTranslation} from "react-i18next";
import {debounce} from "lodash";
import './entity-form.scss';
import {useToastContext} from "../../context/toastContext";
import {BaseEntityNameLess} from "../../api/entities/BaseEntity";
import {MetadataPropertyEntity} from "../../api/entities/replancity_MetadataProperty";
import FieldCreator from "../form/FieldCreator";
import {useTheme} from "../../context/themeContext";
import {
    areAllRequiredFormFieldsFilled,
    getFilledFieldsObjOnFormSubmit,
} from "../../utils/utils";
import {DeleteIcon} from "../icons/icons/DeleteIcon";
import {IconButton} from "../buttons/IconButton/IconButton";
import DeleteEntityModal from "./DeleteEntityModal/DeleteEntityModal";


export type FormDataObject = { [key: string]: string | number };

interface Props {
    loading: boolean;
    entity: BaseEntityNameLess; //remove?
    metadata: MetadataPropertyEntity[];
    changeFormFn: (formDataObj: FormDataObject) => void;
    saveFn: <T, >(formDataObj: FormDataObject, reloadRequired?: boolean) => Promise<void>;
    deleteFn?: ({entity}: { entity: BaseEntityNameLess }) => Promise<void>;
    icons?: JSX.Element | JSX.Element[];
}

const EntityForm = ({
                        loading,
                        entity,
                        metadata,
                        changeFormFn,
                        saveFn,
                        deleteFn,
                        icons,
                        ...props
                    }: Props) => {
    const [formDisabled, setFormDisabled] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [errors, setErrors] = useState({});
    const {addToast} = useToastContext();
    const {t} = useTranslation();
    const {theme} = useTheme();

    useEffect(() => {
        const _errors = {};
        for (const {name, mandatory} of metadata) {
            if (!entity[name] && mandatory) {
                _errors[name] = true;
                setFormDisabled(true);
            }
        }

        setErrors(_errors);
    }, [metadata, entity])

    useEffect(() => {
        setErrors(Object.entries(errors).some(([_, hasError]) => hasError));
    }, [errors]);

    const onFormSubmit = async (event, filteredMetaData: MetadataPropertyEntity[]) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);

        if (!areAllRequiredFormFieldsFilled(formDataObj, filteredMetaData)) {
            addToast(`${t('common.not-all-required-fields-are-filled')}`, 'error');
            return;
        }

        setFormDisabled(true);
        await saveFn(formDataObj);
        setFormDisabled(false);
    };

    const formChangeHandler = debounce(useCallback((event) => {
        const {target: {name, validity}} = event;

        if (formRef.current) {
            const fakeFormEvent = {target: formRef.current};

            const formDataObj = getFilledFieldsObjOnFormSubmit(fakeFormEvent);

            metadata.forEach((metadataEntity, ind) => {
                const {id, name, options, type, saveAndUpdateOnChange, showIf} = metadataEntity;

                const firstOptionValue = options?.[0]?.[type === 'Enum' ? 'propertyName' : 'id'] ?? '';

                const func = new Function('', `return ${showIf.toString()};`);

                const toShowField = func.call(formDataObj);
                if (!Object.hasOwn(formDataObj, name) && toShowField) {
                    formDataObj[name] = firstOptionValue;
                }
            })

            // setEntityCopy(formDataObj as BaseEntityNameLess);

            const curErrors = {...errors, [name]: !validity.valid};
            const shouldDisableSubmit = !validity.valid || Object.entries(curErrors).some(([_, hasError]) => hasError);
            setFormDisabled(shouldDisableSubmit);
            setErrors(prevState => ({...prevState, [name]: !validity.valid}));

            changeFormFn(formDataObj);
        }
    }, [changeFormFn, metadata, errors]), 300);

    const saveFormOnFieldUpdate = async (event: ChangeEvent<HTMLElement>, saveAndUpdateOnChange: boolean) => {
        if (saveAndUpdateOnChange) {
            //TODO commented event.stopPropagation() to fix "stopPropagation is not a function". Verify that no other bugs appeared.
            //in order to stop race conditions between below form submit and formChangeHandler
            // event.stopPropagation();

            formRef.current?.requestSubmit();
            setFormDisabled(true);
            return;
        }
    }

    const getFormFields = (metaData: MetadataPropertyEntity[], entity: any) => {
        return metaData.map((metadataEntity, ind) => {
                const {id, name, saveAndUpdateOnChange, showIf} = metadataEntity;
                const func = new Function('', `return ${showIf.toString()};`);
                const toShowField = func.call(entity);

                return (
                    <React.Fragment key={id}>
                        {
                            toShowField
                                ? <Row>
                                    <FieldCreator
                                        entityId={entity.id}
                                        name={name}
                                        value={entity?.[name]}
                                        metadata={metadataEntity}
                                        onChange={(event: ChangeEvent<HTMLElement>) => saveFormOnFieldUpdate(event, saveAndUpdateOnChange)}
                                        {...(saveAndUpdateOnChange
                                            ? {alertLabel: t('transit-lines.form-save-on-field-update-msg')}
                                            : {})
                                        }
                                        // visible={!!toShowField}
                                        autoFocus={ind === 0}
                                    />
                                </Row>
                                : null
                        }
                    </React.Fragment>
                )
            }
        )
    }

    return (
        <div className={`entity-editor ${theme}`} data-testid="entityEditor">
            <div className="entity-editor__header">
                <div className="title">
                    <Typography
                        sx={{
                            fontSize: '14px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {loading
                            ? null
                            : metadata.length ? entity?.name : t('map.no-editable-fields')}
                    </Typography>
                </div>
                <div className="actions">
                    {
                        icons
                            ? icons
                            : null
                    }
                    {
                        entity.id && deleteFn ?
                            <IconButton
                                icon={DeleteIcon}
                                width={20}
                                height={20}
                                onClick={() => setShowDeleteConfirmation(true)}
                                borderless
                                testId="entityFormDeleteBtn"
                            />
                            : null
                    }
                    {/*{props.summaryIcon?.(entity?.id)}*/}
                </div>
            </div>
            {
                metadata.length
                    ? <Form
                        name="entityForm"
                        onSubmit={(event) => onFormSubmit(event, metadata)}
                        onChange={formChangeHandler}
                        submitBtnTitle={t('common.save')}
                        disabled={formDisabled}
                        ref={formRef}
                        submitBtnTestId="entityFormSubmitBtn"
                        testId="entityForm"
                    >
                        {getFormFields(metadata, entity)}
                    </Form>
                    : null
            }
            {
                showDeleteConfirmation && deleteFn
                    ? <DeleteEntityModal
                        entity={entity}
                        setModalShow={setShowDeleteConfirmation}
                        deleteFn={deleteFn}
                    />
                    : null
            }
        </div>
    )
}

export default React.memo(EntityForm);