import React, {ReactElement, useCallback, useState} from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import './simulation-progress.scss';
import {useTranslation} from "react-i18next";
import useSimulationStateLoader from "../../../hooks/simulation/useSimulationStateLoader";
import {themeColors} from "../../../styles/theme";
import {useTheme} from "../../../context/themeContext";
import {
    getSimulationState,
    getSimulationStateColor,
    isErrorResponse,
    isProjectSimulationInProgress
} from "../../../utils/utils";
import Modal from "../../Modal/Modal";
import SimulationSettings from "../SimulationSettings/SimulationSettings";
import Button from "../../buttons/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InterruptSimulationButton from "./InterruptSimulationButton";
import SimulationLog from "./SimulationLog";
import {SimulationState} from "../../../api/entities/replancity_RunnedAlgorithm";
import RestartSimulationButton from "./RestartSimulationButton";
import {usePresetsContext} from "../../../context/presetsContext";
import {useToastContext} from "../../../context/toastContext";
import LoadingSpinner from "../../Spinner/LoadingSpinner";


const SimulationProgress = () => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const {selectedRunId, loading, loadRuns} = usePresetsContext();
    const {
        simulation: {simulationState, simulationProgress},
        startSimulation,
        restartSimulation,
        getSimulationOptions
    } = useSimulationStateLoader({simulationCompletedFn: loadRuns});
    const {t} = useTranslation();
    const {theme} = useTheme();
    const {addToast} = useToastContext();

    const handleBtnClick = async () => {
        setModalVisible(true);
    }

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, [setModalVisible]);

    const restartSimulationHandler = useCallback(async () => {
        const resp = await restartSimulation();
        if (isErrorResponse(resp)) {
            addToast(typeof resp.error === 'string' ? resp.error : resp.error.error, 'error');
        }
    }, [restartSimulation])

    const getActionButtonBySimulationState = useCallback((state: SimulationState): ReactElement | null => {
        switch (state) {
            case SimulationState.IN_PROGRESS:
                return <InterruptSimulationButton simulationState={state}/>;
            case SimulationState.NEVER:
                return null;
            default: {
                return <RestartSimulationButton simulationState={state} onClick={restartSimulationHandler}/>;
            }
        }
    }, [restartSimulationHandler])

    if (loading) {
        return <LoadingSpinner
            size="13px"
            backgroundColor={'transparent'}
        />;
    }

    return (
        <>
            <div className="simulation-progress" data-testid="projectSimulationProgress">
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {
                        selectedRunId ?
                            <div className="simulation-progress__inner">
                                <Box sx={{position: 'relative', display: 'inline-flex'}}>
                                    <CircularProgress
                                        variant={isProjectSimulationInProgress(simulationState) ? 'indeterminate' : "determinate"}
                                        value={simulationProgress ?? 0}
                                    />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color={theme === 'light' ? themeColors.navyBlue : themeColors.white}
                                        >{`${simulationProgress ?? 0}%`}</Typography>
                                    </Box>
                                </Box>
                                <span
                                    className="simulation-progress__state"
                                    style={{'background': getSimulationStateColor(simulationState)}}
                                    data-testid="simulationProgressState"
                                >
                                    {getSimulationState(simulationState, t)}
                                </span>
                                {getActionButtonBySimulationState(simulationState)}
                                <SimulationLog simulationState={simulationState}/>
                            </div>
                            : null
                    }
                    <Box sx={{ml: 2}}>
                        <Button
                            onClick={handleBtnClick}
                            text={t('simulation.simulation')}
                            colorType="transparent"
                            testId="projectSimulationBtn"
                            // disabled={
                            //   !simulationState || isProjectSimulationInProgress(simulationState)
                            // }
                        />
                    </Box>
                </Box>
            </div>
            {
                modalVisible ?
                    <Modal
                        title={t("simulation.settings-modal-title")}
                        setVisible={setModalVisible}
                        testId="simulationSettingsModal"
                    >
                        <SimulationSettings
                            startSimulationFn={startSimulation}
                            getSimulationOptionsFn={getSimulationOptions}
                            onSimulationFinishFn={closeModal}
                        />
                    </Modal>
                    : null
            }
        </>
    )
}

export default SimulationProgress;