import restClient from "./RestClient";


export const publicTransitApi = {
    cloneLineToPreset: function (
        data: { presetId: string, lineId: string },
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_PublicTransitService/cloneLine`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

    cloneRouteToLine: function (
        data: { routeId: string, lineId: string },
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_PublicTransitService/cloneRouteToLine`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

    invertRoute: function (
        data: { routeId: string },
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_PublicTransitService/invertRoute`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

    addRouteStop: function (
        data: {
            routeProfileStop: {
                stopOrder?: number;
                publicTransitRouteProfile: {
                    id: string;
                },
                transitStop: {
                    id: string;
                }
            }
        },
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "POST",
            path: `services/replancity_TransitRouteService/addPublicTransitRouteProfileStop`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

    rearrangeProfileStops: function (
        data: { transitRouteId: string, profileStopsOrder: string[] },
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "POST",
            path: `services/replancity_TransitRouteService/rearrangeProfileStops`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

};