import {useCallback, useRef, useState} from "react";
import {AxiosError} from "axios";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectCurrentProjectId,
    selectEntity,
    selectEntityMetadata,
} from "../../redux/selectors/selectors";
import {isErrorResponse} from "../../utils/utils";
import {MetadataPropertyEntity} from "../../api/entities/replancity_MetadataProperty";
import {projectsApi} from "../../api/projectsApi";
import {BaseEntity, BaseEntityNameLess} from "../../api/entities/BaseEntity";
import {entityApi, EntityView, SaveEntityProps} from "../../api/entityApi";
import {useDispatch} from "react-redux";
import {useToastContext} from "../../context/toastContext";
import {useTranslation} from "react-i18next";
import {roadManagementApi} from "../../api/roadManagementApi";
import {setEntity, setEntityMetadata} from "../../redux/entity/entity-reducer";
import {DataById} from "../../redux/map/types";
import {usePresetsContext} from "../../context/presetsContext";


export type UseEntityLoaderProps = {
    entityName: string;
    saveFnArgs?: (entity: any) => ({ [key: string]: any });
    presetProperty?: string;
    entityView?: EntityView;
}

export type EntityLoaderReturnType<T extends BaseEntity | BaseEntityNameLess> = {
    loading: boolean;
    entity: T;
    metadata: MetadataPropertyEntity[];
    loadData: ({entityName, entityId, entityView}: {
        entityName: string;
        entityId?: string | null | undefined;
        entityView?: EntityView
    }) => Promise<void>;
    saveFn: ({entityName, id, data}: SaveEntityProps) => Promise<T>;
    groupSaveFn: any;
    deleteFn: ({entity}: { entity: T }) => Promise<any>;
    error: AxiosError | undefined;
}

export const useEntityLoader = <T extends BaseEntityNameLess>({
                                                                  entityName,
                                                                  saveFnArgs,
                                                                  presetProperty,
                                                              }: UseEntityLoaderProps = {} as UseEntityLoaderProps): EntityLoaderReturnType<T> => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<AxiosError>();
    // const [entity, setEntity] = useState<any>({});
    const entity = useTypedSelector((state) => selectEntity(state, entityName));
    // const [metadata, setMetadata] = useState<MetadataPropertyEntity[]>([]);
    const metadata = useTypedSelector<MetadataPropertyEntity[]>((state) => selectEntityMetadata(state, entityName));
    const projectId = useTypedSelector(selectCurrentProjectId);
    const {selectedPresetId} = usePresetsContext();
    const abortControllerRef = useRef(new AbortController());
    const dispatch = useDispatch();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    const getVisibleMetadataAndExtraProps = useCallback((metaData: MetadataPropertyEntity[]): {
        visibleMetadata: MetadataPropertyEntity[],
        extraEntityProps: DataById<unknown>
    } => {
        const extraEntityProps = {};
        const visibleMetadata: MetadataPropertyEntity[] = metaData.filter(({
                                                                               name,
                                                                               type,
                                                                               visible,
                                                                               options
                                                                           }) => {
            if (options.length && !entity?.[name]) {
                const {id: optionId, propertyName} = options[0];
                extraEntityProps[name] = type === 'Enum' ? propertyName : optionId;
            }
            return visible;
        });

        return {visibleMetadata, extraEntityProps};
    }, []);

    const loadMetadata = useCallback(async (entityName: string, selectedPresetId: string): Promise<MetadataPropertyEntity[]> => {
        const metadata: MetadataPropertyEntity[] = await projectsApi.fetchEntityMetadata({
            entityName,
            presetId: selectedPresetId,
            abortSignal: abortControllerRef.current.signal
        });

        return metadata;
    }, [])

    const loadEntity = useCallback(async ({entityName, entityId, entityView}: {
        entityName: string;
        entityId?: string | null | undefined;
        entityView?: EntityView
    }): Promise<T> => {
        // try {
        const resp = await entityApi.getEntity<T>({
            entityId: entityId,
            entityName,
            ...(
                entityView ?
                    {
                        params: {
                            view: entityView
                        }
                    }
                    : {}
            ),
            abortSignal: abortControllerRef.current.signal
        });

        //TODO call flyTo util? example was useRoadSegmentLoader

        return resp;
    }, [])

    const saveFn = useCallback(async ({entityName, id, data: entity}: SaveEntityProps): Promise<T> => {
        setLoading(true);

        const resp = await entityApi.saveEntity<T>({
            id,
            entityName,
            data: {
                ...(presetProperty ?
                    {
                        [presetProperty]: {
                            id: selectedPresetId
                        }
                    } : {}),
                ...entity,
                ...saveFnArgs?.(entity),
            },
            abortSignal: abortControllerRef.current.signal
        });

        setLoading(false);

        return resp;
    }, [projectId, selectedPresetId, saveFnArgs])


    //TODO maybe move somewhere
    const groupSaveFn = useCallback(async ({
                                               ids,
                                               data: roadSegment,
                                               entityName
                                           }) => {
        const resp = await roadManagementApi.groupUpdate(
            {
                roadInfrastructureUpdateTask: {
                    source: {...roadSegment},
                    targets: ids
                }
            },
            abortControllerRef.current.signal
        );

        //TODO currently responses is empty => remove below?
        // if (!isErrorResponse(resp)) {
        //     // setData(resp as any);
        //     dispatch(setEntity(entityName, resp));
        //     dispatch(clearedSelectedMapFeatures());
        // }

        return resp;
    }, [])

    const deleteFn = useCallback(async ({entity}: { entity: T }) => {
        setLoading(true);

        const resp = await entityApi.deleteEntity({
            entityId: entity.id,
            entityName,
            abortSignal: abortControllerRef.current.signal
        });
        if (!isErrorResponse(resp)) {
            addToast(`${t('common.delete-successful')}`, 'success');
        }
        setLoading(false);

        return resp;
    }, [entityName])

    const loadData = useCallback(async ({entityName, entityId, entityView}: {
        entityName: string;
        entityId?: string | null | undefined;
        entityView?: EntityView
    }): Promise<void> => {
        setLoading(true);

        const [metaData, entity] = await Promise.all([loadMetadata(entityName, selectedPresetId), loadEntity({
            entityName,
            entityId,
            entityView
        })])

        if (!isErrorResponse(metadata) && !isErrorResponse(entity)) {
            const {visibleMetadata, extraEntityProps} = getVisibleMetadataAndExtraProps(metaData);
            // setMetadata(visibleMetadata);
            dispatch(setEntityMetadata({entityName, metadata: visibleMetadata}));

            // entity should be set in this order {...extraEntityProps, ...entity} => entity properties should be used if they exist in extraEntityProps
            dispatch(setEntity({
                entityName,
                entity: {
                    ...extraEntityProps,
                    ...entity
                }
            }));
        }

        setLoading(false);
    }, [loadMetadata, loadEntity, selectedPresetId]);

    return {
        loading,
        entity: entity as T ?? {},
        metadata: metadata ?? [],
        loadData,
        saveFn,
        groupSaveFn,
        deleteFn,
        error
    };
};
