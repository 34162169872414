import React from "react";
import {Layer, useMap} from "react-map-gl"
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapboxLayerById, selectMapboxLayerIds} from "../../../redux/selectors/selectors";
import {NamedLayer} from "../../../redux/map/types";
import {useMapFiltersContext} from "../../../context/mapFiltersContext";


type LayerWrapperProps = {
  layerId: string;
  beforeId?: string;
}

const LayerWrapper = ({layerId, beforeId = ''}: LayerWrapperProps) => {
  const layer: NamedLayer = useTypedSelector(state => selectMapboxLayerById(state, layerId));
  const {mapFilter} = useMapFiltersContext();

  const map = useMap();
  const layerAfter = map.current?.getLayer(beforeId);

  return <Layer
      {...(beforeId && layerAfter ? {beforeId} : {})}
      {...(mapFilter.length ? {filter: mapFilter} : {})}
      {...layer}
  />
}

const MemoLayer = React.memo(LayerWrapper)

const MapboxGlLayers = () => {
  const layersIds = useTypedSelector(selectMapboxLayerIds);

  return <>
    {
      layersIds.map((id, ind) =>
        <MemoLayer key={id} layerId={id}
                   beforeId={layersIds[ind + 1] ?? ''}
        />
      )
    }
  </>
}

export default MapboxGlLayers