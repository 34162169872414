import {ComponentType, CSSProperties} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";


type Props<T> = {
    id: string;
    Component: ComponentType<T>;
    props: T;
} //& Partial<CSSProperties & DraggableAttributes & SyntheticListenerMap>
// & EntitiesListRowProps<any>

const DraggableItem = <T,>({id, Component, props}: Props<T>) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        'cursor': 'grab'
    };

    return <Component ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} />;
}

export default DraggableItem;