import React from "react";
import DropDown, {OptionsAlign} from "../DropDown/DropDown";
import {themeColors} from "../../styles/theme";
import {DropDownOptionType} from "../DropDown/Option";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../context/themeContext";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectCurrentProjectModes} from "../../redux/selectors/selectors";
import {DEFAULT_MODE} from "../../hooks/projects/useAllowedModes";
import {useMatch} from "react-router-dom";
import {AllowedProjectModes} from "../../redux/reducers/projectsReducer";


const ProjectModeSelector = () => {
    const match = useMatch('/:projectId/:mode/*');
    const mode = match?.params?.mode ? match.params.mode! : DEFAULT_MODE;
    const projectId = useTypedSelector(selectCurrentProjectId);
    const allowedModes = useTypedSelector(selectCurrentProjectModes);
    const {t} = useTranslation();
    const {theme} = useTheme();
    const themeColor = theme === 'light' ? themeColors.navyBlue : themeColors.white;

    const dropDownTitleByMode = {
        'layers': t('project-modes.layers'),
        'klayers': t('project-modes.kepler-layers'),
        // 'algorithms': t('common.algorithms'),
        'dashboard': t('project-modes.dashboard'),
        'network': t('project-modes.roads-editor'),
        'transit': t('project-modes.pt-editor'),
        'maas': t('project-modes.maas-editor'),
        'config': t('project-modes.admin-settings'),
        'runs': t('project-modes.runs'),
        'ev': t('project-modes.ev'),
        'scenario': t('project-modes.scenario'),
        'logistics': t('project-modes.dv')
    };

    const allowedModesDontExist = Object.values(allowedModes).every(isAllowed => !isAllowed);

    const dropDownOptions: (Omit<DropDownOptionType, 'testId'> & { testId?: keyof AllowedProjectModes })[] = [
        {
            title: t('project-modes.results-section'),
            section: true
        },
        // ...(
        //   allowedModes.runOptimization ? [{
        //     title: t('common.algorithms'),
        //     link: `/${projectId}/algorithms`,
        //   }] : []
        // ),
        {
            title: t('project-modes.layers'),
            link: `/${projectId}/`,
            testId: 'layers'
        },
        ...(
            allowedModes.gisV2Layers ? [{
                title: t('project-modes.kepler-layers'),
                link: `/${projectId}/klayers`,
                testId: 'klayers' as any
            } as const] : []
        ),
        ...(
            allowedModes.biDashboard ? [{
                title: t('project-modes.dashboard'),
                link: `/${projectId}/dashboard`,
                testId: 'biDashboard'
            } as const] : []
        ),
        {
            title: t('project-modes.editors-section'),
            section: true
        },
        ...(
            allowedModes.networkEditor ? [{
                title: t('project-modes.roads-editor'),
                link: `/${projectId}/network`,
                testId: 'networkEditor'
            } as const] : []
        ),
        ...(
            allowedModes.transitScheduleEditor ? [{
                title: t('project-modes.pt-editor'),
                link: `/${projectId}/transit`,
                testId: 'transitScheduleEditor'
            } as const] : []
        ),
        ...(
            allowedModes.sharedMobilityEditor ? [{
                title: t('project-modes.maas-editor'),
                link: `/${projectId}/maas`,
                testId: 'sharedMobilityEditor'
            } as const] : []
        ),
        ...(
            allowedModes.evInfrastructureEditor ? [{
                title: t('project-modes.ev'),
                link: `/${projectId}/ev`,
                testId: 'evInfrastructureEditor'
            } as const] : []
        ),
        ...(
            allowedModes.scenarioPresetEditor ? [{
                title: t('project-modes.scenario'),
                link: `/${projectId}/scenario`,
                testId: 'scenarioPresetEditor'
            } as const] : []
        ),
        ...(
            allowedModes.dvInfrastructureEditor ? [{
                title: t('project-modes.dv'),
                link: `/${projectId}/logistics`,
                testId: 'dvInfrastructureEditor'
            } as const] : []
        ),
        {
            title: t('project-modes.advanced-section'),
            section: true
        },
        ...(
            allowedModes.advancedConfig ? [{
                title: t('project-modes.admin-settings'),
                link: `/${projectId}/config`,
                testId: 'advancedConfig'
            } as const] : []
        ),
        ...(
            allowedModes.runs ? [{
                title: t('project-modes.runs'),
                link: `/${projectId}/runs`,
                testId: 'runs'
            } as const] : []
        )
    ]

    return (
        <>
            <DropDown
                title={dropDownTitleByMode[mode]}
                options={dropDownOptions}
                optionsAlign={OptionsAlign.LEFT}
                disabled={allowedModesDontExist}
                titleColorLight={themeColors.navyBlue}
                titleColorDark={themeColors.white}
                optionColorLight={themeColors.navyBlue}
                optionColorDark={themeColors.white}
                iconColorLight={themeColors.white}
                iconColorDark={themeColors.navyBlue}
                iconRectangleWidth={35}
                tooltip={t('header.select-mode')}
                testId={'projectModesSelector'}
            />
        </>
    )
}

export default ProjectModeSelector;