import React from "react";
import {Breadcrumbs} from "@mui/material";
import {useLocation, useMatches} from "react-router-dom";
import './breadcrumbs-by-routes.scss';
import LinkWrapper from "../LinkWrapper/LinkWrapper";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectEntities} from "../../redux/selectors/selectors";


const BreadcrumbsByRoutes = () => {
    const {pathname: curPathname} = useLocation();
    const entities = useTypedSelector(selectEntities);

    const matches = useMatches()
        .filter((match) => Boolean(match.handle?.['crumb'] as any))
        .map(({handle, ...restMatch}: any) => ({
            ...restMatch,
            crumb: handle.crumb(restMatch.data)
        }));

    return (
        <div className="breadcrumbs">
            <Breadcrumbs aria-label="breadcrumb">
                {matches.map(({pathname, crumb: {label, id, name, entityName, pathnamePostFix, testId}}, index) => {
                    const {name: nameInEntity} = entities[entityName] ?? {};
                    const breadcrumb = `${label ? `${label}: ` : ''}${nameInEntity ?? name ?? id}`;

                    return (
                        <LinkWrapper
                            className="breadcrumbs__item"
                            key={index}
                            to={`${pathname}${pathnamePostFix ? `${pathnamePostFix}` : ''}`}
                            hasLink={pathname !== curPathname}
                            {...(testId ? {'data-testid': testId} : {})}
                        >
                            {breadcrumb}
                        </LinkWrapper>
                    )
                })}
            </Breadcrumbs>
        </div>
    )
}

export default BreadcrumbsByRoutes;