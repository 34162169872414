import React, {useCallback, useMemo} from "react";
import MapFiltersSection from "./MapFiltersSection";
import './map-filters.scss';
import {useTranslation} from "react-i18next";
import SliderField from "../../../../components/form/SliderField/SliderField";
import * as CheckboxField from "../../../../components/form/CheckboxField/CheckboxField";
import {Form} from "../../../../components/form/Form/Form";
import {getFilledFieldsObjOnFormSubmit} from "../../../../utils/utils";
import {LoadingComponent} from "../../../../components/LoadingComponent/LoadingComponent";
import {useMapFiltersContext} from "../../../../context/mapFiltersContext";
import {MapFiltersType} from "../../../../redux/map/types";
import {TemporaryMapFilterType} from "../../RoadNetworkEditorModePage";


const MapRoadsFilter = () => {
    const {
        loading,
        setLoading,
        enabledFilters,
        setEnabledFilters,
        filters,
        setFilters,
        setMapFilter
    } = useMapFiltersContext();
    const {t} = useTranslation();

    const lanesDefaultValue = useMemo(() => {
        return filters['lanesNumMin'] && filters['lanesNumMax']
            ? [filters['lanesNumMin'], filters['lanesNumMax']]
            : [1, 10]
    }, [filters]);

    const getMapFilter = useCallback((enabledFilters: MapFiltersType, filters: TemporaryMapFilterType) => {
        const {modes, lanesNumMin, lanesNumMax, highwayTypes} = filters;

        const _filter: any[] = ["all"];
        if (enabledFilters['modes'].length) {
            const modesFilters = (['any'] as any).concat(
                enabledFilters['modes']
                    .filter(mode => modes[mode])
                    .map(mode => ['in', mode, ['get', 'modes']])
            );
            _filter.push(modesFilters);
        }
        if (lanesNumMin) {
            _filter.push([">=", ['get', 'lanes'], lanesNumMin]);
        }
        if (lanesNumMax) {
            _filter.push(["<=", ['get', 'lanes'], lanesNumMax]);
        }
        if (enabledFilters['highwayTypes'].length) {
            const highwayTypesFilter = enabledFilters['highwayTypes'].filter(highwayType => highwayTypes[highwayType]);
            _filter.push(["in", ['get', 'highway'], ["literal", highwayTypesFilter]]);
        }

        return _filter.length > 1 ? _filter : null;
    }, []);

    const onFormSubmit = useCallback(async (event) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);

        const modes = enabledFilters['modes'].reduce((acc, name) => {
            acc[name] = formDataObj[name] === 'true';
            return acc;
        }, {});

        const [lanesNumMin, lanesNumMax] = JSON.parse(formDataObj['lanes'] as string);

        const highwayTypes = enabledFilters['highwayTypes'].reduce((acc, name) => {
            acc[name] = formDataObj[name] === 'true';
            return acc;
        }, {});

        const filters: TemporaryMapFilterType = {
            modes,
            lanesNumMin,
            lanesNumMax,
            highwayTypes
        }

        const mapFilter = getMapFilter(enabledFilters as MapFiltersType, filters);
        setMapFilter(mapFilter);
        setFilters(filters);

    }, [setMapFilter, enabledFilters])

    return (
        <div className="map-filters">
            <LoadingComponent isLoading={loading}>
                <Form
                    name="mapFiltersForm"
                    onSubmit={onFormSubmit}
                    submitBtnTitle={t('map-filters.apply-filters-btn')}
                    testId="mapFiltersForm"
                    submitBtnTestId="mapFiltersFormSubmitBtn"
                >
                    <MapFiltersSection title={t('map-filters.transport-modes-group')}>
                        <div className="map-filters__modes-section">
                            {
                                enabledFilters['modes']?.map((name, idx) => {
                                    if (name) {
                                        return <CheckboxField.Labeled
                                            key={idx}
                                            inline={true}
                                            name={name}
                                            value={`${filters['modes'][name]}`}
                                            alignCheckboxRight
                                            testId={`${name}_mode_checkbox`}
                                        />;
                                    }
                                })
                            }
                        </div>
                    </MapFiltersSection>
                    <MapFiltersSection title={t('map-filters.lanes-number-group')}>
                        <SliderField
                            name="lanes"
                            min={1}
                            max={11}
                            step={1}
                            defaultValue={lanesDefaultValue}
                            floatLabels={false}
                            required
                        />
                    </MapFiltersSection>
                    <MapFiltersSection title={t('map-filters.highway-type-group')}>
                        <div className="map-filters__modes-section">
                            {
                                enabledFilters['highwayTypes']?.map((name, idx) => {
                                    return <CheckboxField.Labeled
                                        key={idx}
                                        inline={true}
                                        name={name}
                                        value={`${filters['highwayTypes'][name]}`}
                                        alignCheckboxRight
                                    />;
                                })
                            }
                        </div>
                    </MapFiltersSection>
                </Form>
            </LoadingComponent>
        </div>
    )
}

export default MapRoadsFilter;