import React, {useState} from "react";
import {FieldWithLabel} from "../../../hoc/withClass";
import {FormFieldProps} from "../Form/Form";
import './color-picker-field.scss';
import classNames from "classnames";


interface Props extends FormFieldProps {
  value: string;
  linkable?: boolean;
}

const ColorPickerField = ({name, value, onChange, linkable, ...props}: Props) => {
  const [color, setColor] = useState<string>(value);

  const onChangeHandler = (e) => {
    setColor(e.target.value);
    onChange?.(e);
  }

  const className = classNames(
    'text-field-md',
    'color-picker-field',
    linkable && 'color-picker-field_link'
  );

  return (
    <div className="form-field-md">
      <input
        type="color"
        name={name}
        className={className}
        value={color}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  )
}

const Labeled = FieldWithLabel(ColorPickerField);

export {Labeled};

export default ColorPickerField;