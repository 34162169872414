import React from "react";
import "./layers-list.scss";


type Props = {
    title: string;
}

const LayersListGroup = ({title, children}: React.PropsWithChildren<Props>) => {
    return (
        <div className="layers-group">
            {title}
            {children}
        </div>
    )
}

export default LayersListGroup;