import * as React from "react";
import {Navigate, Route} from "react-router-dom";
import RoadsList from "./RoadNetworkManager/Roads/RoadsList";
import RoadEdit from "./RoadNetworkManager/Roads/RoadEdit";
import Road from "./RoadNetworkManager/Roads/Road";
import RoadSegments from "./RoadNetworkManager/RoadSegments/RoadSegments";
import RoadSegmentEdit from "./RoadNetworkManager/RoadSegments/RoadSegmentEdit";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import GroupSelection from "./RoadNetworkManager/GroupSelection/GroupSelection";
import BreadcrumbedComponent from "../../components/BreadcrumbedComponent/BreadcrumbedComponent";
import RoadRouteChecker from "./RoadNetworkManager/RoadRouteChecker/RoadRouteChecker";
import MapRoadsFilter from "./RoadNetworkManager/MapFilters/MapRoadsFilter";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";


const RoadNetworkEditor = React.lazy(() => import('./RoadNetworkEditorModePage'));

export const useRoadsRoutes = () => {
    return (
        <Route path="network" element={<LazyLoadingWrapper element={<RoadNetworkEditor/>}/>}>
            <Route index element={<Navigate to="roads" replace/>}/>
            <Route
                element={<BreadcrumbedComponent/>}
                handle={{crumb: (data: any) => ({name: 'Roads', ...data})}}
            >
                <Route path="roads">
                    <Route index element={<RoadsList/>}/>
                    <Route path="new" element={<RoadEdit/>}/>
                    <Route
                        path=":roadId"
                        element={<Road/>}
                        loader={({params}) => params}
                        handle={{
                            crumb: ({roadId}) => ({
                                id: roadId,
                                entityName: 'replancity_RoadInfrastructureRoadName',
                                testId: 'roadIdBreadcrumb'
                            })
                        }}
                    >
                        <Route index element={<RoadSegments/>}/>
                        <Route path="edit" element={<RoadEdit/>}/>
                        <Route path=":new" element={<RoadSegmentEdit/>}/>
                        <Route
                            path=":roadSegmentId/edit"
                            element={<RoadSegmentEdit/>}
                            loader={({params}) => params}
                            handle={{
                                crumb: ({roadSegmentId}) => ({
                                    id: roadSegmentId,
                                    entityName: 'replancity_RoadInfrastructureSegment',
                                })
                            }}
                        />
                    </Route>
                </Route>
            </Route>
            <Route
                path='layers'
                element={
                    <LayersListWrapper>
                        <LayersTimeSelectors/>
                    </LayersListWrapper>
                }
            />
            <Route path='groupselection' element={<GroupSelection/>}/>
            <Route path='checkroute' element={<RoadRouteChecker/>}/>
            <Route path='mapfilters' element={<MapRoadsFilter/>}/>
            {/*<Route*/}
            {/*    path="toll-pairs"*/}
            {/*    element={<BreadcrumbedComponent/>}*/}
            {/*    handle={{crumb: (data: any) => ({name: 'Toll pairs', ...data})}}*/}
            {/*>*/}
            {/*    <Route index element={<TollPairsList/>}/>*/}
            {/*    <Route*/}
            {/*        path=":tollPairId"*/}
            {/*        element={<TollPairEdit/>}*/}
            {/*        loader={({params}) => params}*/}
            {/*        handle={{crumb: ({tollPairId}) => ({id: tollPairId})}}*/}
            {/*    />*/}
            {/*</Route>*/}
            {/*<Route*/}
            {/*    path="toll-zones"*/}
            {/*    element={<BreadcrumbedComponent/>}*/}
            {/*    handle={{crumb: (data: any) => ({name: 'Toll zones', ...data})}}*/}
            {/*>*/}
            {/*    <Route index element={<TollZonesList/>}/>*/}
            {/*    <Route*/}
            {/*        path=":tollZoneId"*/}
            {/*        element={<TollZoneEdit/>}*/}
            {/*        loader={({params}) => params}*/}
            {/*        handle={{crumb: ({tollZoneId}) => ({id: tollZoneId})}}*/}
            {/*    />*/}
            {/*</Route>*/}
        </Route>
    )
}