import React, {useEffect} from "react";
import {PresetEntityName, usePresetsContext} from "../../context/presetsContext";
import {LoadingBackdrop} from "../LoadingBackdrop/LoadingBackdrop";


type Presets = {
    type: 'preset';
    presetEntityName: PresetEntityName;
};

type Runs = {
    type: 'run',
    presetEntityName?: never;
};

type Props = | Runs | Presets;

const PresetsLoader = ({type, presetEntityName, children}: React.PropsWithChildren<Props>) => {
    const {loading, selectedPresetId, loadRuns, loadPresets, cleanPresets} = usePresetsContext();

    useEffect(() => {
        return () => {
            cleanPresets();
        }
    }, []);

    useEffect(() => {
        (async function () {
            if (type === 'preset') {
                await Promise.all([loadPresets(presetEntityName), loadRuns({selectRunId: false})]);
            } else if (type === 'run') {
                await loadRuns();
            }

        }());
    }, [type, presetEntityName])

    return (
        <LoadingBackdrop isVisible={
            loading
            || (type === 'preset' && (presetEntityName !== presetEntityName || !selectedPresetId))
        } transparent>
            {children}
        </LoadingBackdrop>
    )
}

export default PresetsLoader;