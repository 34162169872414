import * as React from 'react';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import Projects from "./components/Projects";
import ProjectView from "./containers/ProjectView/ProjectView";
import Profile from "./containers/Settings/Profile";
import {SignUpPage} from "./pages/SignUpPage";
import ProtectedRoute from "./hoc/ProtectedRoute";
import {SignInPage} from "./pages/SignInPage/SignInPage";
import {ResetPassWordPage} from "./pages/ResetPassWordPage";
import ProjectsLoaderWrapper from "./hoc/ProjectsLoaderWrapper";
import {NewPasswordPage} from "./pages/NewPasswordPage/NewPasswordPage";
import {useMaasRoutes} from "./project-modes/MaasMode/Routes";
import {useRoadsRoutes} from "./project-modes/RoadNetworkMode/Routes";
import {useTransitRoutes} from "./project-modes/PublicTransitMode/Routes";
import {useBiDashboardsRoutes} from "./project-modes/BiDashboardMode/Routes";
import {useConfigurationRoutes} from "./project-modes/ConfigurationMode/Routes";
import {useRunsRoutes} from "./project-modes/RunsMode/Routes";
import {useLayersRoutes} from "./project-modes/LayersMode/Routes";
import {useEvInfrastructureRoutes} from "./project-modes/EvInfrastructure/Routes";
import {useScenarioPresetsRoutes} from "./project-modes/ScenarioPresetsMode/Routes";
import {useLogisticsRoutes} from "./project-modes/LogisticsMode/Routes";
import LazyLoadingWrapper from "./components/LazyLoadingWrapper/LazyLoadingWrapper";


const SuperSetAnonymousWindowDashboard = React.lazy(() => import('./containers/Dashboard/SuperSetAnonymousWindowDashboard'));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route path="/:projectId/dashboard/:runId/:dashboardId/sdashboard"
                   element={<LazyLoadingWrapper element={<SuperSetAnonymousWindowDashboard/>}/>}
            />
            <Route element={<ProtectedRoute/>}>
                <Route element={<ProjectsLoaderWrapper/>}>
                    <Route index element={<Projects/>}/>
                    <Route path="/:projectId" element={<ProjectView/>}>
                        {useLayersRoutes()}
                        {/*{useAlgorithmsRoutes()}*/}
                        {useBiDashboardsRoutes()}
                        {useRoadsRoutes()}
                        {useTransitRoutes()}
                        {useMaasRoutes()}
                        {useLogisticsRoutes()}
                        {useConfigurationRoutes()}
                        {useRunsRoutes()}
                        {useEvInfrastructureRoutes()}
                        {useScenarioPresetsRoutes()}
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                    </Route>
                    {/*<Route path="/project" element={<ProtectedRoute><ProjectEdit/></ProtectedRoute>}/>*/}
                    {/*<Route path="Generation">*/}
                    {/*<Route*/}
                    {/*  path="ProjectStart"*/}
                    {/*  element={*/}
                    {/*    <ProtectedRoute><ProjectStart tabName="Generation" pathname="ProjectStart"/></ProtectedRoute>*/}
                    {/*  }*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="Output"*/}
                    {/*  element={*/}
                    {/*    <ProtectedRoute><SimulationResults tabName="Generation" pathname="Output"/></ProtectedRoute>*/}
                    {/*  }></Route>*/}
                    {/*</Route>*/}
                    <Route path="settings">
                        <Route path="profile" element={<Profile/>}/>
                    </Route>
                </Route>
            </Route>
            {/*<Route path="Visualisation" element={<ProtectedRoute><Viz/></ProtectedRoute>}/>*/}
            <Route path="/signin" element={<SignInPage/>}/>
            <Route path="/signup" element={<SignUpPage/>}/>
            <Route path="/reset-password" element={<ResetPassWordPage/>}/>
            <Route path="/new-password" element={<NewPasswordPage/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Route>
    )
);

export const AppRoutes = () => {
    return <RouterProvider router={router}/>
};
