import React from "react";
import {Outlet} from "react-router-dom";
import TawkChat from "../components/TawkChat/TawkChat";
import {ProjectProvider} from "../context/ProjectContext";


const ProjectsLoaderWrapper = () => {
    return (
        <ProjectProvider>
            <Outlet/>
            <TawkChat/>
        </ProjectProvider>
    );
}

export default ProjectsLoaderWrapper;