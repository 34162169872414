import React, {useCallback, useEffect, useRef} from "react";
import {DataById, LayerTypes} from "../../../../redux/map/types";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {useDispatch} from "react-redux";
import {changedLayerVisibility, updatedMapboxLayersOrder} from "../../../../redux/map/map-reducer";
import "./layers-list.scss";
import {useTranslation} from "react-i18next";
import {
    selectLayersWithSameSourceById,
    selectAllLayersById,
    selectListLayers,
} from "../../../../redux/selectors/selectors";
import LoadingSpinner from "../../../../components/Spinner/LoadingSpinner";
import {
    isLayerVisibleInList,
    isLayerVisibleOnMap,
} from "../../../../utils/mapUtils";
import {useTheme} from "../../../../context/themeContext";
import {AlertRhombusIcon} from "../../../../components/icons/icons/AlertRhombusIcon";
import Tooltip from "@mui/material/Tooltip";

import {
    closestCenter,
    DndContext,
    MouseSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    restrictToParentElement,
    restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import type {DragEndEvent} from "@dnd-kit/core/dist/types";
import LayersListGroup from "./LayersListGroup";
import DraggableItem from "../../../../components/DraggableItem/DraggableItem";
import {isUuidString} from "../../../../utils/utils";


type RowProps<T> = {
    rowItem: T;
    visible: boolean;
    dayTimeDependent?: boolean;
    draggable?: boolean;

    style?: any;
    attributes?: any;
    listeners?: any;

};

type LayerRowProps = RowProps<LayerTypes>;

export const LayerRow = React.memo(React.forwardRef(({
                                                         rowItem,
                                                         visible,
                                                         dayTimeDependent,
                                                         ...rest
                                                     }: LayerRowProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const mounted = useRef<boolean>(false);
    const layersWithSameSource: DataById<LayerTypes> = useTypedSelector(state => selectLayersWithSameSourceById(state, rowItem.source));
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        if (mounted.current) {
            dispatch(changedLayerVisibility({layer: rowItem, visible}));
        } else {
            mounted.current = true;
        }
    }, [visible]);

    const changeVisibility = ({target}) => {
        Object.values(layersWithSameSource).map(layer => {
            dispatch(changedLayerVisibility({layer, visible: target.checked}));
        })
    }

    return (
        <div
            id={rowItem.id}
            className="layer-row-item layer-row-item_colored"
            ref={ref}
            data-testid="layersListRow"
            {...rest}
        >
            <div className="row-check">
                {
                    rowItem.loading
                        ? <LoadingSpinner
                            size="13px"
                            backgroundColor={'transparent'}
                        />
                        : <input
                            type="checkbox"
                            checked={visible}
                            onChange={changeVisibility}
                        />
                }
            </div>
            <div className="row-name" draggable>
                <span>{rowItem.layerName}</span>
            </div>
            <div className="layer-row-item__controls">
                {
                    dayTimeDependent
                        ?
                        <Tooltip title={t('layers-list.daytime-dependent-layer-warning')}
                                 placement="top">
                                    <span>
                                        <AlertRhombusIcon/>
                                    </span>
                        </Tooltip>
                        : null
                }
            </div>
        </div>
    );
}));

export const LayersList = () => {
    const allLayersById = useTypedSelector(selectAllLayersById);
    const listLayers = useTypedSelector(selectListLayers);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {theme} = useTheme();

    const sensors = useSensors(
        useSensor(MouseSensor,
            {
                activationConstraint: {
                    distance: 10,
                },
            }
        ),
    );

    const allLayersSelectionHandler = ({target}) => {
        Object.values(allLayersById).map(layer => dispatch(changedLayerVisibility({layer, visible: target.checked})));
    };

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            let oldIndex = 0, newIndex = 0;

            listLayers.layersIds.forEach((id, ind) => {
                if (id === active.id) {
                    oldIndex = ind;
                }
                if (id === over.id) {
                    newIndex = ind;
                }
            });

            dispatch(updatedMapboxLayersOrder({oldIndex, newIndex}));
        }
    }, [listLayers])

    return (
        <div
            className={`layers-list-container ${theme}`}
            data-testid="layersList"
        >
            <DndContext
                sensors={sensors}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={listLayers.layersIds}
                    strategy={verticalListSortingStrategy}
                >
                    {
                        listLayers.layersIds.some(id => isUuidString(id))
                            ? <>
                                <div className="layers-list-header">
                                    <div className="row-check">
                                        <input
                                            type="checkbox"
                                            onChange={allLayersSelectionHandler}
                                        />
                                    </div>
                                    <div className="row-name">
                                        <span>{t('layers-list.all-layers')}</span>
                                    </div>
                                </div>
                                {
                                    Object.entries(listLayers.layersByGroup).map(([group, layers], groupInd) => {
                                        let noVisibleLayers = true;

                                        const layersElements = layers.map((layer, ind) => {
                                            const {id, dayTimeDependent} = layer;

                                            if (!isLayerVisibleInList(layer, layer.state)) {
                                                return <React.Fragment key={`${layer.id}_${ind}`}></React.Fragment>
                                            }

                                            noVisibleLayers = false;

                                            return (
                                                <DraggableItem<LayerRowProps>
                                                    key={`${id}_${ind}`}
                                                    id={id}
                                                    Component={LayerRow}
                                                    props={{
                                                        rowItem: layer,
                                                        visible: isLayerVisibleOnMap(layer),
                                                        dayTimeDependent,
                                                    }}
                                                />
                                            );
                                        });

                                        return (
                                            <React.Fragment key={groupInd}>
                                                {
                                                    noVisibleLayers
                                                        ? null
                                                        : <LayersListGroup title={group}>
                                                            {layersElements}
                                                        </LayersListGroup>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </>
                            : t('map.no-layers-exist-warning')
                    }
                </SortableContext>
            </DndContext>
        </div>
    );
};

export default LayersList;
