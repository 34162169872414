import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";


export interface DropDownOptionType {
    id?: string;
    title: string;
    name?: string;
    onClick?: any;
    link?: string;
    replace?: boolean;
    image?: JSX.Element | null;
    rightIcon?: React.ReactElement;
    className?: string;
    textColor?: string;
    testId?: string;
    section?: boolean;
}

const DropDownOption = ({
                            title,
                            onClick,
                            link,
                            replace = false,
                            image,
                            rightIcon,
                            className,
                            textColor,
                            testId,
                            section
                        }: DropDownOptionType) => {
    const optionClassName = classNames(
        'dropdown-option',
        className && className,
        section && 'section-option'
    )

    const getOption = () => {
        return (
            <div
                onClick={onClick}
                className={optionClassName}
                {...(testId ? {'data-testid': testId} : {})}
            >
                <div className="dropdown-option__icon">
                    {image}
                </div>
                <div className="dropdown-option__text" style={{color: textColor}}>
                    {title}
                </div>
                <div>
                    {rightIcon}
                </div>
            </div>
        )
    }

    return (
        <>
            {link ? (
                    <Link to={link} replace={replace}>
                        {getOption()}
                    </Link>
                ) :
                getOption()
            }
        </>
    )
}

export default DropDownOption