import restClient, {ApiError} from "./RestClient";
import {MapConfig} from "./entities";
import {
  AnyLayerParams,
} from "./entities/local/Borders";
import {MapConfigProps} from "./types/types";
import {MapLayerDataType, TripsLayerDataType} from "./entities/replancity_MapLayer";
import {FeatureProperties} from "../redux/map/types";
import {EntityServiceName} from "./enums/enums";


export type LayerCreationParameter = {
  name: {
    id: string;
  };
  value: any
}

const mapApi = {
  getMapConfig: function ({
                            projectId,
                            mapConfigType,
                            presetId,
                            runId,
                            abortSignal
                          }: MapConfigProps): Promise<MapConfig | ApiError> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/${mapConfigType}`,
      data: {
        ...(presetId ? {presetId} : {}),
        ...(runId ? {runId} : {}),
      },
      fetchOptions: {signal: abortSignal}
    });
  },

  getMapConfigByServiceName: function ({serviceName, presetId, runId, abortSignal}: {
    serviceName: string,
    presetId?: string,
    runId?: string;
    abortSignal: AbortSignal
  }): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `services/${serviceName}/getMapConfig`,
      data: {
        presetId,
        ...(runId ? {runId} : {}),
      },
      fetchOptions: {signal: abortSignal}
    });
  },

  fetchAnyLayerData: function (
    projectId: string,
    data: AnyLayerParams,
    abortSignal: AbortSignal
  ): Promise<MapLayerDataType> {
    return restClient.fetch({
      method: "POST",
      path: `projects/${projectId}/layer_envelope_endpoint_bodyparam/`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

  fetchTripsLayerData: function (
    data: {runId?: string;},
    abortSignal: AbortSignal
  ): Promise<TripsLayerDataType> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_DynamicVisualizationService/getRidingVehiclesOutput`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

  getFeatureById: function (
    featureId: string | number,
    entityName: string,
    abortSignal: AbortSignal,
    findParent = false
  ): Promise<GeoJSON.Feature<GeoJSON.Geometry, Omit<FeatureProperties, 'layerId'>>> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_EntityFeatureProvideService/${findParent ? 'findParentByMetadataAndId' : 'findFeatureByMetadataAndId'}?metadataName=${entityName}&entityId=${featureId}`,
      fetchOptions: {signal: abortSignal}
    });
  },

  calculateLayer: function (
    projectId: string,
    layerId: string | number,
  ): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/calculate_layer/${layerId}`
    });
  },

  getHighlightedFeature: function (
    entityName: string,
    entityId: string,
    projectId: string,
    abortSignal: AbortSignal
  ): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_EntityFeatureProvideService/drawHighlightCircles?metadataName=${entityName}&entityId=${entityId}&projectId=${projectId}`,
      fetchOptions: {signal: abortSignal}
    });
  },

  getListRelatedFeatures: function (data: {
                                      sourceId: string;
                                      sourceEntityType: string;
                                      targetEntityType: string;
                                    },
                                    serviceName: EntityServiceName.ROAD_INFRASTRUCTURE | EntityServiceName.PUBLIC_TRANSIT,
                                    abortSignal: AbortSignal,
  ): Promise<{
    featureId: number;
    relatedFeatures: string[];
    uuid: string;
  } | ApiError> {
    return restClient.fetch({
      method: "GET",
      path: `services/${serviceName}/listRelatedFeatures`,
      fetchOptions: {signal: abortSignal},
      data
    });
  },

}

export default mapApi;