import React, {CSSProperties, useEffect, useRef, useState} from "react";
import "./drop-down.scss";
import useOutsideClickListener from "./useOutsideClickListener";
import {MenuUpIcon} from "../icons/icons/MenuUpIcon";
import {MenuDownIcon} from "../icons/icons/MenuDownIcon";
import classNames from "classnames";
import Options from "./Options";
import DropDownOption, {DropDownOptionType} from "./Option";
import {themeColors} from "../../styles/theme";
import {ThemeType, useTheme} from "../../context/themeContext";
import Tooltip from "@mui/material/Tooltip";


export enum OptionsAlign {
    LEFT = 'left',
    RIGHT = 'right'
}

type DropDownProps = {
    title?: JSX.Element | string;
    titleColorLight?: string;
    titleColorDark?: string;
    options: DropDownOptionType[];
    selectedOptionInd?: number;
    optionColorLight?: string;
    optionColorDark?: string;
    containerClassName?: string;
    coloredButton?: boolean;
    outlined?: boolean;
    optionsAlign?: OptionsAlign;
    disabled?: boolean;
    iconColorLight?: string;
    iconColorDark?: string;
    iconRectangleWidth?: number;
    tooltip?: string;
    testId?: string;
    maxWidth?: string;
};

const DropDown = React.memo(({
                                 title,
                                 titleColorLight = themeColors.grey,
                                 titleColorDark = themeColors.grey3,
                                 options,
                                 selectedOptionInd = 0,
                                 optionColorLight = themeColors.grey,
                                 optionColorDark = themeColors.grey3,
                                 containerClassName,
                                 optionsAlign = OptionsAlign.LEFT,
                                 outlined = true,
                                 disabled = false,
                                 iconColorLight = themeColors.grey,
                                 iconColorDark = themeColors.navyBlue,
                                 iconRectangleWidth = 24,
                                 tooltip,
                                 testId,
                                 maxWidth
                             }: DropDownProps) => {
    const [visible, setVisible] = useState(false);
    const [selectedOption, setOption] = useState<DropDownOptionType>(options[selectedOptionInd]);
    const wrapper = useRef<any>(null);
    const [popupPosition, setPosition] = useState<CSSProperties>();
    const {theme} = useTheme();
    useOutsideClickListener(wrapper, setVisible);

    useEffect(() => {
        setOption(options[selectedOptionInd])
    }, [selectedOptionInd, options]);

    const contentClassName = classNames(
        'content-wrapper',
        outlined && 'content-wrapper_outlined',
        containerClassName && containerClassName,
        disabled && 'disabled',
    )

    const iconClassName = classNames(
        'dropdown__icon',
        outlined && 'dropdown__icon_outlined',
    )

    const getIcon = (theme: ThemeType, iconColorLight: string, iconColorDark: string) => visible
        ? <MenuUpIcon
            color={theme === 'light' ? iconColorLight : iconColorDark}
        />
        : <MenuDownIcon
            color={theme === 'light' ? iconColorLight : iconColorDark}
        />;

    const handleClick = () => {
        if (disabled) {
            return;
        }

        setVisible(!visible);

        const parent = wrapper?.current?.getBoundingClientRect();
        const popupPosition: CSSProperties = optionsAlign == OptionsAlign.LEFT ?
            {left: 0} :
            {right: 0}; // TODO check in future
        setPosition(popupPosition);
    }

    const handleSelection = (e, option: DropDownOptionType) => {
        setOption(option);
        option?.onClick?.();
    }

    return (
        <Tooltip title={tooltip ?? ''} placement="top" ref={wrapper} disableHoverListener={disabled}>
            <div
                onClick={handleClick}
                className="dropdown"
                data-testid={testId}
                style={maxWidth ? {maxWidth} : {}}
            >
                <div className={contentClassName}>
                    <div className="dropdown__content">
                        <span
                            className="dropdown__title"
                            style={{color: theme === 'light' ? titleColorLight : titleColorDark}}
                            data-testid={`${testId}Title`}
                        >
                            {title ? title : selectedOption?.title}
                        </span>
                        <span className="dropdown__content-icon">
                            {selectedOption?.rightIcon ? selectedOption.rightIcon : null}
                        </span>
                    </div>
                    <div
                        className={`${iconClassName} ${theme}`}
                        style={{width: iconRectangleWidth}}
                    >
                        {getIcon(theme, iconColorLight, iconColorDark)}
                    </div>
                </div>
                {visible && (
                    <Options style={{...popupPosition}}>
                        <>
                            {options.map((option, ind) => {
                                return (
                                    <DropDownOption
                                        {...option}
                                        key={ind}
                                        onClick={(e) => handleSelection(e, option)}
                                        textColor={theme === 'light' ? optionColorLight : optionColorDark}
                                        replace={!!option?.replace}
                                    />
                                )
                            })}
                        </>
                    </Options>)
                }
            </div>
        </Tooltip>
    );
});

export default DropDown;
