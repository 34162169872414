import React, {useRef} from "react";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const TAWK_PROPERTY_ID = '65b8bfcc8d261e1b5f59616c';
const TAWK_WIDGET_ID = '1hlcqqdo7';

const customStyle = {
    visibility: {
        //for desktop only
        desktop: {
            position: 'br', // bottom-right
            xOffset: 45,
            yOffset: 40
        },
        // for mobile only
        mobile: {
            position: 'bl', // bottom-left
            xOffset: 5,
            yOffset: 50
        },
        // change settings of bubble if necessary
        bubble: {
            rotate: '0deg',
            xOffset: -40,
            yOffset: 0
        }
    }
};

const TawkChat = () => {
    const tawkRef = useRef();

    return (
        <TawkMessengerReact
            propertyId={TAWK_PROPERTY_ID}
            widgetId={TAWK_WIDGET_ID}
            ref={tawkRef}
            customStyle={customStyle}
        />
    )
}

export default TawkChat;