import React, {useCallback, useEffect} from "react";
import "./css/app.scss";
import {useToastContext} from "./context/toastContext";
import {useTranslation} from "react-i18next";
import {AppRoutes} from "./AppRoutes";


function App() {
  const {addToast} = useToastContext();
  const {t} = useTranslation();

  const offlineEventListener = useCallback(() => {
    addToast(`${t('common.app-offline')}`, 'error', false);
  }, [t, addToast])

  const onlineEventListener = useCallback(() => {
    addToast(`${t('common.app-online')}`, 'success');
  }, [t, addToast])

  useEffect(() => {
    window.addEventListener('offline', offlineEventListener);
    window.addEventListener('online', onlineEventListener);

    return () => {
      window.removeEventListener('offline', offlineEventListener);
      window.removeEventListener('online', onlineEventListener);
    }
  }, [offlineEventListener, onlineEventListener])

  return (
    <div id="app">
      <AppRoutes/>
    </div>
  );
}

export default App;