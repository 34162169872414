import React, {memo, useEffect, useRef} from "react";
import DrawControl, {drawRef} from "../../../containers/map/tools/DrawControl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {
  selectCutMode,
  selectIsDrawMode,
  selectSnapMode,
  selectEnabledFeaturesEditing,
  selectFeaturesWithParentsByEntityName,
} from "../../../redux/selectors/selectors";
import {isDrawMode} from "../../../utils/mapUtils";
import {
  setDrawMode,
  setSnapMode,
} from "../../../redux/draw/draw-reducer";
import MapSnapButton from "../Controls/MapSnapButton";
import MapCutMode from "../Controls/MapCutMode";
import {DrawControlsClass} from "../../../utils/DrawControlsClass";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import {useLayersInteractivity} from "../../../hooks/map/useLayersInteractivity";


export interface DrawerProps {
  drawControls?: MapboxDraw.MapboxDrawControls | undefined;
  onDrawModeChange?: (evt: MapboxDraw.DrawModeChangeEvent) => void;
  onFeaturesDraw?: (event: MapboxDraw.DrawCreateEvent | MapboxDraw.DrawUpdateEvent) => void;
  editableFeatureTypes?: {[T in GeoJSON.Geometry['type']]?: boolean};
}

const Drawer = memo(({
                       drawControls,
                       onDrawModeChange,
                       onFeaturesDraw,
                       editableFeatureTypes = {
                         Point: true,
                         MultiPoint: true,
                         LineString: true,
                         MultiLineString: true,
                         Polygon: true,
                         MultiPolygon: true
                       }
                     }: DrawerProps) => {
  const isMounted = useRef<boolean>(false);
  const selectedFeaturesIdsRef = useRef<string[]>([]);
  const featuresWithParentByEntityName = useTypedSelector(selectFeaturesWithParentsByEntityName);
  const editingEnabled = useTypedSelector(selectEnabledFeaturesEditing);
  const _isDrawMode = useTypedSelector(selectIsDrawMode);
  const snapMode = useTypedSelector(selectSnapMode);
  const cutMode = useTypedSelector(selectCutMode);
  const {setDrawing, saveDrawnFeatures, recoverSettingsAfterDrawing} = useLayersInteractivity();
  const dispatch = useDispatch();

  const defaultEditableFeatureTypes = {
    Point: true,
    MultiPoint: true,
    LineString: true,
    MultiLineString: true,
    Polygon: true,
    MultiPolygon: true,
    ...editableFeatureTypes
  };

  const keyPress = (event: KeyboardEvent) => {
    //TODO navigate to list on Escape click?
    if (event.key === 'Escape') {
      recoverSettingsAfterDrawing();
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', keyPress);

    DrawControlsClass.findAndHideDrawControls();

    return () => {
      setDrawing(false)
      document.removeEventListener('keyup', keyPress);
      // drawRef?.changeMode('simple_select');
    }
  }, []);

  useEffect(() => {
    if (!_isDrawMode) {
      drawRef?.deleteAll();
    }
  }, [_isDrawMode]);

  useEffect(() => {
    if (isMounted.current && drawRef) {
      drawRef['options']['snap'] = snapMode;
    }
  }, [snapMode]);

  useEffect(() => {
    if (isMounted.current) {
      if (cutMode) {
        drawRef?.changeMode('cut_line');
      } else if (!cutMode) {
        drawRef?.changeMode('simple_select');
        drawRef?.deleteAll();
      }
    } else {
      isMounted.current = true;
    }
  }, [cutMode]);

  useEffect(() => {
    if (editingEnabled) {
      const featureWithParentLst = Object.values(featuresWithParentByEntityName);
      selectedFeaturesIdsRef.current = featureWithParentLst
          .filter(({feature: {properties}}) => !!properties?.featureStateId)
          .map(({feature: {properties: {featureStateId}}}) => featureStateId.toString());

      for (const {feature} of featureWithParentLst) {
        if (feature?.properties?.id && feature?.geometry?.type) {
          const {properties, geometry} = feature;
          const {id} = properties ?? {};
          const {type} = geometry;

          (async function () {

            //TODO check defaultEditableFeatureTypes[type] condition. maybe to use map interactiveLayerIds prop? does it work for editor?
            if (id && !drawRef?.get(id) && feature && defaultEditableFeatureTypes[type]) {
              drawRef?.add(feature);
            }
          }());

          if (!_isDrawMode && id && !selectedFeaturesIdsRef.current.find(featId => featId === id)) {
            drawRef?.delete(id);
          }
        }
      }
    }
    else if (!_isDrawMode) {
      drawRef?.deleteAll();
    }
  }, [editingEnabled, defaultEditableFeatureTypes, _isDrawMode, featuresWithParentByEntityName]);

  const onModeChange = (event: MapboxDraw.DrawModeChangeEvent) => {
    const {mode} = event;

    if (isDrawMode(mode)) {
      setDrawing(true);

      // point feature creation and 'direct_select' mode cause error in snapping lib
      if (mode !== 'direct_select' && mode !== 'draw_point') {
          dispatch(setSnapMode(true));
      }
    }
    dispatch(setDrawMode(mode));

    onDrawModeChange?.(event);
  }

  const onUpdate = (event: MapboxDraw.DrawCreateEvent | MapboxDraw.DrawUpdateEvent) => {
    const {features} = event
    saveDrawnFeatures(features);

    onFeaturesDraw?.(event);
  }

  return <>
    {
      //TODO hide controls if drawer is unused
      // isDrawMode(drawRef?.getMode()) &&
      <DrawControl
        position="top-right"
        displayControlsDefault={false}
        controls={{
          trash: true,
          point: true,
          line_string: true,
          polygon: true,
          ...drawControls,
        }}
        boxSelect={true}
        touchEnabled={false}
        defaultMode={'simple_select'}
        onCreate={onUpdate}
        onUpdate={onUpdate}
        // onDelete={onDelete}
        onDrawModeChange={onModeChange}
      />
    }
    {
      _isDrawMode ? (
        <>
          <MapCutMode/>
          <MapSnapButton/>
        </>
    )
    : null
    }
  </>
})

export default Drawer;