import React, {memo} from "react";
import {IconButton} from "../../buttons/IconButton/IconButton";
import {RestoreIcon} from "../../icons/icons/RestoreIcon";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {SimulationState} from "../../../api/entities/replancity_RunnedAlgorithm";


const RestartSimulationButton = ({simulationState, onClick}: {
    simulationState: SimulationState,
    onClick: () => Promise<any>
}) => {
    const {t} = useTranslation();

    return (
        <Tooltip title={t('simulation.restart-simulation-tooltip')}>
            <IconButton
                icon={RestoreIcon}
                width={20}
                height={20}
                onClick={onClick}
                disabled={
                    simulationState === SimulationState.NEVER
                    || simulationState === SimulationState.RESTARTING
                    || simulationState === SimulationState.TERMINATING
                }
                borderless
            />
        </Tooltip>
    );
}

export default memo(RestartSimulationButton);