import * as React from "react";
import {
  Outlet,
  useNavigate,
} from 'react-router-dom';
import {useAuth} from "../context/authContext";
import {useEffect} from "react";

const ProtectedRoute = () => {
  const {token} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/signin', {state: {from: location.pathname}});
    }
  }, [token]);


  return <Outlet/>;
};

export default ProtectedRoute;