import React, {useCallback, useEffect} from "react";
import {useParams} from "react-router-dom";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";
import mapApi from "../../../../api/mapApi";
import {EntityServiceName} from "../../../../api/enums/enums";
import {isErrorResponse} from "../../../../utils/utils";
import {requireToHighlightFeatures} from "../../../../redux/map/map-reducer";
import {useDispatch} from "react-redux";


const StopEdit = () => {
    const stopEntityName = PublicTransitLayerEntityName.STOPS;
    const routesEntityName = PublicTransitLayerEntityName.ROUTES;
    const {stopId} = useParams<any>();
    const stopsLayerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, PublicTransitLayerEntityName.STOPS));
    const routesLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, PublicTransitLayerEntityName.ROUTES));
    const dispatch = useDispatch();

    useEffect(() => {
        (async function () {
            const abortController = new AbortController();
            const resp = await mapApi.getListRelatedFeatures({
                    sourceId: stopId!,
                    sourceEntityType: stopEntityName,
                    targetEntityType: routesEntityName
                },
                EntityServiceName.PUBLIC_TRANSIT,
                abortController.signal);

            if (!isErrorResponse(resp)) {
                const {featureId, relatedFeatures} = resp;

                const featureProperties = relatedFeatures.map(id => ({
                    id,
                    featureStateId: id,
                    layerId: routesLayerId,
                    entityName: routesEntityName
                }));
                featureProperties.push({
                    id: stopId!,
                    featureStateId: featureId.toString(),
                    layerId: stopsLayerId,
                    entityName: stopEntityName
                });

                dispatch(requireToHighlightFeatures({
                    featureProperties
                }));
            }
        }());
    }, [stopEntityName, routesEntityName, stopId, stopsLayerId])

    //TODO fix "fly to" and stop highlighting from useTransitStopLoader

    const getUrlOnSaveFn = useCallback((entity: any) => {
        if (!stopId) {
            return `../`;
        }
    }, [stopId]);

    const onDeleteFn = useCallback((entity: any) => '../', []);

    return (
        <EntityManagerCleaner entityName={stopEntityName}>
            <EntityEditor
                entityId={stopId!}
                entityName={stopEntityName}
                layerId={stopsLayerId}
                presetProperty={'publicTransitPreset'}
                getUrlOnSaveFn={getUrlOnSaveFn}
                getUrlOnDeleteFn={onDeleteFn}
            />
        </EntityManagerCleaner>
    )
}

export default StopEdit;