import React from "react";
import './project-layout.scss';
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectCurrentProjectId,
    selectCurrentProjectName,
    selectMapConfigCoordinates,
} from "../../redux/selectors/selectors";
import Header from "../../components/Header";
import ProjectModeSelector from "../../components/ProjectModeSelector/ProjectModeSelector";
import ExportMatsimButton from "../../components/ExportMatsimButton/ExportMatsimButton";
import SearchHeader from "../../components/SearchHeader";
import {CoordinatesType} from "../../redux/map/types";
import MapSkeleton from "../../components/Skeletons/MapSkeleton";
import LeftPanel
    from "../../components/panels/LeftPanel";
import SkeletonComponent from "../../components/Skeletons/SkeletonComponent";
import MapSelectionArea from "../../containers/MapSelectionArea/MapSelectionArea";
import {PresetEntityName} from "../../context/presetsContext";
import {useInternetSpeedMeasurer} from "../../hooks/useInternetSpeedMeasurer";
import PresetsLoader from "../../components/PresetsLoader/PresetsLoader";


type Props = {
    subHeaderLeft?: React.ReactNode;
    main: React.ReactNode;
    leftPanel?: React.ReactNode;
    rightPanel?: React.ReactNode;
    leftPanelTitle?: string;
    showSkeleton?: boolean;
    skeletonExplanation?: string;
    presetEntityName?: PresetEntityName;
}

const ProjectLayout = ({
                         subHeaderLeft,
                         main,
                         leftPanel,
                         rightPanel,
                         leftPanelTitle,
                         showSkeleton,
                         skeletonExplanation,
                         presetEntityName
                     }: Props) => {
    const projectId = useTypedSelector(selectCurrentProjectId);
    const projectName = useTypedSelector(selectCurrentProjectName);
    const {longitude, latitude} = useTypedSelector<CoordinatesType>(selectMapConfigCoordinates);

    useInternetSpeedMeasurer();

    const coordinatesExist = longitude && latitude;

    //TODO add project/presets/runs loaded check, then uncomment
    // useEffect(() => {
    //     if (showSkeleton && skeletonExplanation) {
    //         addToast(skeletonExplanation, 'error');
    //     }
    // }, [showSkeleton, skeletonExplanation]);

    if (!coordinatesExist && projectId) {
        return <MapSelectionArea/>;
    }

    return (
        <PresetsLoader {...(presetEntityName ? {type: 'preset', presetEntityName} : {type: 'run'})}>
            <div className="project-page" data-testid="projectPage">
                <Header projectName={projectName}/>
                <SearchHeader
                    leftFields={subHeaderLeft}
                    rightFields={
                        <>
                            <ProjectModeSelector/>
                            <ExportMatsimButton/>
                        </>
                    }
                />
                <main className="project-page_main">
                    {
                        showSkeleton
                            ? <MapSkeleton/>
                            : main
                    }
                    {
                        leftPanel
                            ? (
                                <div id="info-panels">
                                    <LeftPanel
                                        title={leftPanelTitle}
                                    >
                                        {
                                            showSkeleton
                                                ? <SkeletonComponent skeletonsNum={5}/>
                                                : leftPanel
                                        }
                                    </LeftPanel>
                                </div>
                            )
                            : null
                    }
                    {
                        rightPanel
                            ? rightPanel
                            : null
                    }
                </main>
            </div>
        </PresetsLoader>
    )
}

export default ProjectLayout;