import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import MapGL from "../../../components/map/MapGL";
import './map-container.scss';
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {
  selectIsViewPortFixed,
  selectMapConfigCoordinates,
  selectPointToFlyTo,
  selectIsDrawMode,
  selectCurrentProjectId,
  selectDrawnFeatures,
  selectSourcesById,
  selectDrawMode,
  selectAllSourceIds,
} from "../../../redux/selectors/selectors";
import ErrorComponent from "../../../components/ErrorComponent/ErrorComponent";
import {ViewState, ViewStateChangeEvent} from "react-map-gl/src/types/external";
import {MapLayerMouseEvent} from "react-map-gl/dist/esm/types";
import {LoadingBackdrop} from "../../../components/LoadingBackdrop/LoadingBackdrop";
import {
  CoordinatesType,
  FeatureProperties,
  FeatureState,
  MapboxGeoJSONFeatureWithProperties
} from "../../../redux/map/types";
import {flewToPoint, setViewportCoordinates} from "../../../redux/map/map-reducer";
import {useDispatch} from "react-redux";
import {ViewPortCoordinates} from "../../../api/entities/local/Borders";
import {useTheme} from "../../../context/themeContext";
import {
  areCoordinatesEqual,
  areCoordinatesExist,
  getFeatureCollection,
  getMapPointBounds,
  getUniqueFeatures,
  layerHasDontShowState,
  removeFeatureState,
  setFeatureState
} from "../../../utils/mapUtils";
import mapboxgl from 'mapbox-gl';
import satelliteIcon from '../../../assets/satellite.svg';
import MapControlButton from "../../../components/map/Controls/MapControls/MapControlButton";
import {useTranslation} from "react-i18next";
import RulerControl from "../../../components/map/Controls/RulerControl/RulerControl";
import useMapFeatureHighlighting from "../../../hooks/map/useMapFeatureHighlighting";
import {addProjectStorageMapViewState, getStorageMapViewStatesByProjectId} from "../../../utils/projectUtils";
import useMapConfigLoader from "../../../hooks/map/useMapConfigLoader";
import {EntityServiceName} from "../../../api/enums/enums";
import {DefaultLayerType} from "../../../hooks/map/useDefaultLayers";
import {MapRef, Popup} from "react-map-gl";
import {drawRef} from "../tools/DrawControl";
import {isUuidString} from "../../../utils/utils";
import {SimulationState} from "../../../api/entities/replancity_RunnedAlgorithm";


export interface MapContainerProps {
  mapConfigType?: EntityServiceName,
  defaultSources?: DefaultLayerType[],
  handleMapClick?: (event: mapboxgl.MapLayerMouseEvent, isDrawing?: boolean) => void;
  // onErrorFn?: (isError: boolean) => void;
}

const DEFAULT_VIEWSTATE: ViewState = {
  longitude: 0,
  latitude: 0,
  zoom: 1,
  bearing: 0,
  pitch: 0,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }
}

const MapContainer = React.memo(({
                                   children,
                                   mapConfigType = EntityServiceName.MAP_CONFIG,
                                   defaultSources,
                                   handleMapClick,
                                   // onErrorFn,
                                 }: React.PropsWithChildren<MapContainerProps>) => {
  const [isError, setError] = useState<boolean>(false);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [dragPan, setDragPan] = useState<boolean>(true);
  const [popupData, setPopupData] = useState<{ latitude: number; longitude: number; data: string; } | undefined>();
  const mapRef = useRef<MapRef>();
  const hoveredFeatures = useRef<{ featureStateId: string; layerId: string; }[]>([]);
  const layerIdsWithFilters = useRef<(string)[]>([]);
  const throttlingRef = useRef(false);
  const projectId = useTypedSelector(selectCurrentProjectId);
  const isDrawMode = useTypedSelector(selectIsDrawMode);
  const drawMode = useTypedSelector(selectDrawMode);
  // const legendsById = useTypedSelector(selectSourceLegendsById);
  const {longitude, latitude} = useTypedSelector<CoordinatesType>(selectMapConfigCoordinates);
  const isViewPortFixed = useTypedSelector(selectIsViewPortFixed);
  const pointToFlyTo = useTypedSelector(selectPointToFlyTo);
  const drawnFeatures = useTypedSelector(selectDrawnFeatures) as GeoJSON.Feature<any, Partial<FeatureProperties>>[];
  const sourcesById = useTypedSelector(selectSourcesById);
  const allSourceIds = useTypedSelector(selectAllSourceIds);
  const {
    loading: mapConfigLoading,
    loaded: mapConfigLoaded,
    error: mapConfigError
  } = useMapConfigLoader(mapConfigType, defaultSources);
  const [viewState, setViewState] = useState<ViewState>({
    ...DEFAULT_VIEWSTATE,
    latitude,
    longitude,
    ...(getStorageMapViewStatesByProjectId()[projectId] ?? {}),
  });
  const {addFeatureSelectionEventListeners, featureClickListener, removeFeatureSelectionEventListeners} = useMapFeatureHighlighting(setDragPan);
  const dispatch = useDispatch();
  const {theme} = useTheme();
  const {t} = useTranslation();

  const mapStyleByTheme = `mapbox://styles/mapbox/${theme === 'light' ? 'streets-v11' : 'dark-v10'}?optimize=true`;
  const mapSatelliteStyle = `mapbox://styles/mapbox/satellite-v9?optimize=true`;
  const [style, setStyle] = useState(mapStyleByTheme);

  const allLayersIds = useMemo(() => {
    return allSourceIds.filter(id => {
      const {calculationState} = sourcesById[id];
      return isUuidString(id) && calculationState && !layerHasDontShowState(calculationState as SimulationState);
    });
  }, [allSourceIds])

  useEffect(() => {
    const coordinatesExist = longitude && latitude;

    setViewState({
      ...DEFAULT_VIEWSTATE,
      ...(coordinatesExist
          ? {
            longitude,
            latitude,
            zoom: 13
          }
          : {}
      ),
      ...(getStorageMapViewStatesByProjectId()[projectId] ?? {}),
    })

    return () => {
      removeFeatureSelectionEventListeners();
    }
  }, [])

  useEffect(() => {
    if (!isViewPortFixed) {
      updateViewportCoordinates();
    }
  }, [isViewPortFixed])

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map && mapLoaded) {
      addFeatureSelectionEventListeners(map);
    }
  }, [mapLoaded]);

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map) {
      const currentMapCenterCoord = map.getCenter();

      const {lng, lat} = pointToFlyTo;
      // if (areCoordinatesExist(pointToFlyTo) && !areCoordinatesEqual(pointToFlyTo, currentMapCenterCoord)) {
      if (lng && lat) {
        map.fitBounds(pointToFlyTo);
      }
        // dispatch(flewToPoint());
      // }
    }
  }, [pointToFlyTo]);

  useEffect(() => {
    if (isDrawMode) {
      addRenderedFeaturesToDraw();
    }
  }, [isDrawMode]);

  useEffect(() => {
    const map = mapRef.current?.getMap();

    if (map) {
      if (drawnFeatures?.length) {
        const firstFeature = drawnFeatures[0];
        const {layerId, featureStateId} = firstFeature.properties;

        // Filtering should be done for existing features, not for new one
        if (layerId && featureStateId) {
          layerIdsWithFilters.current.push(layerId);

          const ids = drawnFeatures.map(({properties: {featureStateId}}) => featureStateId);
          const filterIds = ['!', ['in', ['get', 'featureStateId'], ['literal', ids]]];

          map.setFilter(layerId, filterIds);
        } else if (featureStateId && !layerId) {
          console.error(`Replan. No layerId property in feature:`, firstFeature);
        }
      } else {
        // Removes layers filters previously set
        for (const layerId of layerIdsWithFilters.current) {
          map.setFilter(layerId);
        }
      }
    }
  }, [drawnFeatures]);

  function updateViewportCoordinates() {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      const {_ne, _sw} = map.getBounds();

      const coordinates: ViewPortCoordinates = {
        minLat: _sw.lat,
        maxLat: _ne.lat,
        minLon: _sw.lng,
        maxLon: _ne.lng,
        zoom: map.getZoom()
      }

      dispatch(setViewportCoordinates({viewPortCoordinates: coordinates}));
    }
  }

  const sourceLoadThrottling = () => {
    if (throttlingRef.current) {
      return;
    }

    throttlingRef.current = true
    setTimeout(() => {
      throttlingRef.current = false
      updateViewportCoordinates();
    }, 300)
  }

  const onViewStateChange = useCallback((viewState: ViewStateChangeEvent) => {
    setViewState(viewState.viewState);
    addProjectStorageMapViewState(projectId, viewState.viewState);
  }, [])

  const onMapLoaded = useCallback(() => {
    updateViewportCoordinates();
    setMapLoaded(true);

    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = '';
    }
  }, [])

  const mouseEnterListener = useCallback((event: MapLayerMouseEvent) => {
    if (isDrawMode) {
      return;
    }

    if (event.features?.length) {
      const map = mapRef?.current?.getMap();
      if (map) {
        map.getCanvas().style.cursor = 'pointer';

        const {features, lngLat: {lat, lng}} = event;

        const uniqueFeatures = getUniqueFeatures(features as MapboxGeoJSONFeatureWithProperties[]);

        uniqueFeatures.map(({properties: {featureStateId, instanceName}, source}) => {
          const featureStateIdStr = featureStateId.toString();

          const {hasBalloon} = sourcesById[source];

          hoveredFeatures.current.push({featureStateId: featureStateIdStr, layerId: source});

          if (map.getLayer(source) && featureStateId) {
            setFeatureState({
              mapRef: map,
              featureStateId: featureStateIdStr,
              layerId: source,
              stateProperty: FeatureState.HOVERED,
              stateValue: true
            });
          }

          if (hasBalloon) {
            setPopupData({data: instanceName ?? '', latitude: lat, longitude: lng});
          }
        })
      }

    }
  }, [isDrawMode, sourcesById])

  const mouseLeaveListener = useCallback(() => {
    if (isDrawMode) {
      return;
    }

    const map = mapRef?.current?.getMap();
    if (map) {
      map.getCanvas().style.cursor = '';

      hoveredFeatures.current.map(({featureStateId, layerId}) => {
        if (map.getLayer(layerId) && featureStateId) {
          removeFeatureState({
            mapRef: map,
            featureStateId: featureStateId.toString(),
            layerId,
            stateProperty: FeatureState.HOVERED
          });
        }
      });

      setPopupData(undefined);
    }
  }, [isDrawMode])

  const addRenderedFeaturesToDraw = useCallback(() => {
    // 'direct_select' mode causes error in snapping lib
    if (isDrawMode && mapRef.current && drawMode !== 'direct_select' ) {
      const boundsByPoints = getMapPointBounds(mapRef.current.getMap());
      const features = mapRef.current?.queryRenderedFeatures(
          boundsByPoints,
          {
            layers: allLayersIds,
          }
      );

      if (features.length) {
        const featureColl = getFeatureCollection(features as any);
        drawRef?.add(featureColl);
      }
    }
  }, [allLayersIds, isDrawMode, drawMode])

  const mapMoveEndListener = useCallback(() => {
    addRenderedFeaturesToDraw();
    sourceLoadThrottling();
  }, [addRenderedFeaturesToDraw])

  const mapClickListener = (event: mapboxgl.MapLayerMouseEvent) => {
    if (isDrawMode) {
      return;
    }

    featureClickListener(event);

    handleMapClick?.(event, isDrawMode);
  }

  const changeMapStyle = () => {
    setStyle(prevStyle => prevStyle === mapStyleByTheme ? mapSatelliteStyle : mapStyleByTheme);
  }

  // Used to completely block map double click events, because doubleClickZoom props in MapGL doesn't work on pages with Drawer
  // double click is blocked to avoid wrong behavior of Draw lines
  const onDblClick = (event: mapboxgl.MapMouseEvent) => {
    event.preventDefault();
  }

  return (
      <ErrorComponent error={mapConfigError}>
        <LoadingBackdrop
          isVisible={mapConfigLoading || !mapLoaded}
          transparent
        >
          <div className="map-wrapper" data-testid="mapWrapper">
            {viewState && mapConfigLoaded ?
                <MapGL
                    {...viewState}
                    onClick={mapClickListener}
                    onMove={onViewStateChange}
                    onLoad={onMapLoaded}
                    onMouseEnter={mouseEnterListener}
                    onMouseLeave={mouseLeaveListener}
                    onDragEnd={mapMoveEndListener}
                    onZoomEnd={mapMoveEndListener}
                    onDblClick={onDblClick}
                    ref={mapRef}
                    mapStyle={style}
                    dragPan={dragPan}
                >
                  {
                    mapLoaded
                        ? <>
                          {children}
                          <div id="map-top-toolbar"></div>
                          <div id="map-bottom-toolbar"></div>
                          <MapControlButton
                              title={t('map.change-map-style-btn')}
                              position="top-right"
                              icon={satelliteIcon}
                              eventHandler={changeMapStyle}
                          />
                          {
                            mapRef.current ?
                                <RulerControl map={mapRef.current.getMap()}/>
                                : null
                          }
                          {
                            popupData
                                ? <Popup
                                    latitude={popupData.latitude}
                                    longitude={popupData.longitude}
                                    offset={20}
                                    closeButton={false}
                                >
                                  {popupData.data}
                                </Popup>
                                : null
                          }
                        </>
                        : null
                  }
                </MapGL> :
                isError ?
                    <ErrorComponent/>
                    : null
            }
          </div>
        </LoadingBackdrop>
    </ErrorComponent>
  )
})

MapContainer.displayName = 'MapContainer';

export default MapContainer;