import React, {useCallback} from "react";
import SimulationProgress from "../../components/ProjectSimulation/SimulationProgress/SimulationProgress";
import ProjectLayout from "../../pages/ProjectLayout/ProjectLayout";
import RunSelector from "../../components/RunSelector/RunSelector";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import SidePanelManager from "../../components/LayersManager/SidePanelManager";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import RunsToCompareSelector from "../../components/RunSelector/RunsToCompareSelector";
import {useDispatch} from "react-redux";
import {MapboxGeoJSONFeatureWithProperties} from "../../redux/map/types";
import {setSelectedMapFeatures} from "../../redux/showInfo/showInfoReducer";
import {SelectedFeatureProperties} from "../../redux/showInfo/types";
import {useTranslation} from "react-i18next";


const LayersModePage = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    //TODO this is temporary to fix SidePAnel opening
    const mapClickListener = useCallback((event: mapboxgl.MapLayerMouseEvent, isDrawing?: (boolean | undefined)) => {
        const {features} = event;
        if (features?.length) {
            const selectedFeaturesProperties = features.map(feature => {
                    const {
                        properties: {
                            id,
                            featureStateId,
                            layerId,
                            entityName
                        }
                    } = feature as MapboxGeoJSONFeatureWithProperties;
                    return {id, featureStateId: featureStateId.toString(), layerId, entityName};
                }
            ) satisfies SelectedFeatureProperties[];
            dispatch(setSelectedMapFeatures(selectedFeaturesProperties));
        }
    }, [])

    return (
        <ProjectLayout
            subHeaderLeft={
                <>
                    <RunSelector/>
                    <SimulationProgress/>
                    <RunsToCompareSelector label={t('header.select-run-to-compare-with')}/>
                </>
            }
            main={<MapContainerWithSources handleMapClick={mapClickListener}/>}
            leftPanel={
                <LayersListWrapper>
                    <LayersTimeSelectors/>
                </LayersListWrapper>
            }
            // leftPanelTitle={t('map.visible-layers-title')}
            rightPanel={<SidePanelManager/>}
        />
    )
}

export default LayersModePage;