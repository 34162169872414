import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Outlet, useParams} from "react-router-dom";
import IconTabs from "../../../../components/LeftPanelTabs/IconTabs";
import {RoadEditorIcon, RoadSegmentEditorIcon} from "../../../../components/icons/icons";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";
import mapApi from "../../../../api/mapApi";
import {isErrorResponse} from "../../../../utils/utils";
import {requireToHighlightFeatures} from "../../../../redux/map/map-reducer";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import {useDispatch} from "react-redux";
import {EntityServiceName} from "../../../../api/enums/enums";


const Road = () => {
    const roadEntityName = 'replancity_RoadInfrastructureRoadName';
    const roadSegmentEntityName = 'replancity_RoadInfrastructureSegment';
    const {t} = useTranslation();
    const {roadSegmentId, roadId} = useParams<any>();
    const segmentsLayerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, roadSegmentEntityName));
    const dispatch = useDispatch();

    //TODO add icons
    const tabs = [
        {
            path: '',
            label: t('road-network.road-segments'),
            url: './',
            icon: <div style={{color: '#fff'}}>S</div>,
            testId: 'roadSegmentsTab'
        },
        {
            path: 'edit',
            label: t('road-network.edit-road'),
            url: './edit',
            icon: <RoadEditorIcon/>,
            testId: 'editRoadTab'
        },

    ];

    if (roadSegmentId) {
        tabs.push({
            path: ":roadSegmentId/edit",
            label: "Segment",
            url: `${roadSegmentId}/edit`,
            icon: <RoadSegmentEditorIcon/>,
            testId: 'editRoadSegmentTab'
        })
    }

    useEffect(() => {
        (async function () {
            const abortController = new AbortController();
            const resp = await mapApi.getListRelatedFeatures({
                    sourceId: roadId!,
                    sourceEntityType: roadEntityName,
                    targetEntityType: roadSegmentEntityName
                },
                EntityServiceName.ROAD_INFRASTRUCTURE,
                abortController.signal);

            if (!isErrorResponse(resp)) {
                const {relatedFeatures} = resp;

                const featureProperties = relatedFeatures.map(id => ({
                    id,
                    featureStateId: id,
                    layerId: segmentsLayerId,
                    entityName: roadSegmentEntityName
                }));

                dispatch(requireToHighlightFeatures({
                    featureProperties
                }));
            }
        }());
    }, [roadId, roadSegmentEntityName, roadSegmentEntityName, segmentsLayerId]);

    return (
        <>
            <IconTabs tabs={tabs}/>
            <EntityManagerCleaner entityName={roadEntityName}>
                <Outlet/>
            </EntityManagerCleaner>
        </>
    )
}

export default Road;