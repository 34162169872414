import React from "react";
import StopEntityRowForm from "../Stops/StopEntityRowForm";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {EntityServiceName} from "../../../../api/enums/enums";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";


const getUrlFunc = ({id}: any) => `./${id}/edit`

const TransitStopsList = () => {
    const entityName = PublicTransitLayerEntityName.STOPS;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;

    return (
        <EntitiesLoader
            entityName={entityName}
            layerEntityName={entityName}
            serviceName={serviceName}
            form={StopEntityRowForm}
            // linkable={true}
            getUrlFunc={getUrlFunc}
            zoomToRelatedFeatureBbox={false}
        />
    )
}

export default TransitStopsList;