import * as React from "react";
import {Route} from "react-router-dom";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const LogisticsEditor = React.lazy(() => import('./LogisticsPage'));

export const useLogisticsRoutes = () => {
    return (
        <Route path="logistics" element={<LazyLoadingWrapper element={<LogisticsEditor/>}/>}/>
    );
}