import React, {useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import {IconButton} from "../../../../components/buttons/IconButton/IconButton";
import {CutIcon} from "../../../../components/icons/icons/CutIcon";
import {CopyIcon} from "../../../../components/icons/icons/CopyIcon";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";
import {useCloneTransitEntities} from "../../../../hooks/transitlLines/useCloneTransitEntities";
import Portal from "../../../../components/Portal/Portal";
import {LoadingBackdrop} from "../../../../components/LoadingBackdrop/LoadingBackdrop";
import Modal from "../../../../components/Modal/Modal";
import {Form, Row} from "../../../../components/form/Form/Form";
import * as SelectBox from "../../../../components/form/SelectBox/SelectBox";
import {useToastContext} from "../../../../context/toastContext";
import {useEntitiesLoader} from "../../../../hooks/entites/useEntitiesLoader";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {EntityServiceName} from "../../../../api/enums/enums";
import {ActionsProps} from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";


const CopyRouteToLineAction = ({id, loadEntitiesFn}: ActionsProps) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const {loading, cloneRouteToLine, invertRoute} = useCloneTransitEntities();
    const {lineId} = useParams<any>();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    const {
        loading: linesLoading,
        entities,
    } = useEntitiesLoader({
        entityName: PublicTransitLayerEntityName.LINES,
        serviceName: EntityServiceName.PUBLIC_TRANSIT,
    });

    const linesOptions = entities.map(({id, name}) => ({
        caption: name,
        value: id
    }))

    const invertRouteHandler = useCallback(async (routeId: string) => {
        const resp = await invertRoute({routeId});
        if (!isErrorResponse(resp)) {
            await loadEntitiesFn();
            addToast(`${t('common.save-successful')}`, 'success');
        }
    }, [addToast])

    const copyBtnHandler = useCallback(() => {
        setModalVisible(true);
    }, [id])

    const copyRouteToLine = useCallback(async (event, routeId: string) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);
        const {lineId} = formDataObj;

        const resp = await cloneRouteToLine({routeId, lineId: lineId.toString()});
        if (!isErrorResponse(resp)) {
            await loadEntitiesFn();
            addToast(`${t('common.save-successful')}`, 'success');
        }

        setModalVisible(false);
    }, [setModalVisible, cloneRouteToLine, id]);

    return (
        <>
            <Tooltip title={t('transit-lines.invert-route')} placement="top">
                <IconButton
                    icon={CutIcon}
                    width={20}
                    height={20}
                    onClick={() => invertRouteHandler(id)}
                    borderless
                />
            </Tooltip>
            <Tooltip title={t('transit-lines.copy-route-to-line-btn-tooltip')} placement="top">
                <IconButton
                    icon={CopyIcon}
                    width={20}
                    height={20}
                    onClick={copyBtnHandler}
                    borderless
                />
            </Tooltip>
            {
                modalVisible ?
                    <Portal targetNodeId={"app"}>
                        <LoadingBackdrop
                            isVisible={loading || linesLoading}
                            transparent
                        >
                            <Modal
                                title={t("transit-lines.copy-route-to-line-modal-title")}
                                setVisible={setModalVisible}
                            >
                                <Form
                                    name="copyRouteToLineForm"
                                    onSubmit={(event) => copyRouteToLine(event, id)}
                                    submitBtnTitle={t('common.confirm')}
                                    // disabled={!selectedPresetId}
                                >
                                    <Row>
                                        <SelectBox.Labeled
                                            label={t('transit-lines.copy-route-to-line-select')}
                                            name='lineId'
                                            options={linesOptions}
                                            value={lineId}
                                        />
                                    </Row>
                                </Form>
                            </Modal>
                        </LoadingBackdrop>
                    </Portal>
                    : null
            }
        </>
    )
}

export default CopyRouteToLineAction;