import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import cloneDeep from "lodash/cloneDeep";
import placeholder from "../assets/Placeholder.svg";
import windowClose from "../assets/window-close.svg";
import {useNavigate} from "react-router-dom";
import "./file-upload.scss";
import Button from "../components/buttons/Button";
import {projectsApi} from "../api/projectsApi";
import {useTypedSelector} from "../redux/Hooks/storeSelectors";
import {useTranslation} from "react-i18next";
import {selectCurrentProjectId} from "../redux/selectors/selectors";
import {isErrorResponse} from "../utils/utils";
import {useProjectContext} from "../context/ProjectContext";
import {useToastContext} from "../context/toastContext";
import {LoadingBackdrop} from "../components/LoadingBackdrop/LoadingBackdrop";
import {useTheme} from "../context/themeContext";


const formData = new FormData();

const FilesUploader = ({setVisible}) => {
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const projectId = useTypedSelector(selectCurrentProjectId)
  const [filesById, setFilesById] = useState<{[key: string]: File}>({});
  const [saving, setSaving] = useState<boolean>(false);
  const {loadProjects} = useProjectContext();
  const navigate = useNavigate();
  const {addToast} = useToastContext();
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: [".xml", ".gz"],
  });
  const {t} = useTranslation();
  const {theme} = useTheme();

  const filesList = useMemo(() => {
    return Object.values(filesById);
  }, [filesById]);

  const fields = [
    {
      labelName: t('file-upload.config-file'),
      name: "config",
      type: 'select',
      required: true,
      namePattern: '.*(config).*'
    },
    {
      labelName: t('file-upload.network'),
      name: "network",
      type: 'select',
      required: true,
      namePattern: '.*(network).*'
    },
    {labelName: t('file-upload.plans'), name: "plans", type: 'select', required: true, namePattern: '.*(plans).*'},
    {
      labelName: t('file-upload.transit-schedule-file'),
      name: "transitSchedule",
      type: 'select',
      namePattern: '.*(schedule).*'
    },
    {
      labelName: t('file-upload.transit-vehicles-file'),
      name: "transitVehicles",
      type: 'select',
      namePattern: '.*(vehicles).*'
    },
    {
      labelName: t('file-upload.attributes-file'),
      name: "attributesFile",
      type: 'select',
      namePattern: '.*(attributes).*'
    },
    {labelName: t('file-upload.vehicles-file'), name: "vehicles", type: 'select'},
    {
      labelName: t('file-upload.epsg-code'),
      name: 'epsgCode',
      type: 'input',
      placeholder: "for example: 25833",
      required: true
    },
    {
      labelName: t('file-upload.percentage-of-agents-in-model'),
      name: "percentageOfAgentsInModel",
      type: 'input',
      placeholder: "for example: 10",
      required: true,
      pattern: "[1-9]{1,2}[0]?|100"
    }
  ];

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      setFilesById(prevState => ({...prevState, [file.name]: file}));
    });
  }, [acceptedFiles]);

  const checkSubmitDisability = useCallback((formData) => {
    for (const {name, required} of fields) {
      if (!formData.has(name) && required) {
        setDisabledSubmit(true);
        return;
      }
      setDisabledSubmit(false);
    }
  }, [fields]);

  useEffect(() => {
    checkSubmitDisability(formData);
  }, [checkSubmitDisability, formData]);

  function deleteFile(name: string) {
    setFilesById(prevState => {
      const {[name]: removedFile, ...restFiles} = prevState;
      return restFiles;
    });
  }

  function onOptionChange(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>, isFile = true) {
    const {name, value} = e.target;
    if (isFile) {
      const file = filesById[value] || null;
      if (file) {
        formData.set(name, file, file.name);
      }
    } else {
      if (value) {
        formData.set(name, value);
      } else {
        formData.delete(name);
      }
    }

    checkSubmitDisability(formData);
  }

  async function createProject() {
    if (!projectId) {
      return;
    }

    setSaving(true);
    const resp = await projectsApi.uploadFiles(projectId, formData);
    setSaving(false);

    if (!isErrorResponse(resp)) {
      await loadProjects();
      navigate(`./${projectId}`);
    } else {
      console.error('FilesUploader createProject error:', resp);
      const {error, status} = resp;
      addToast(`${error['code']}. ${error['status']}: ${error['message']}`, 'error', false);
    }

  }

  function InputSections() {
    const buf = cloneDeep(filesList);
    return (
        <>
          {fields.map(({name, labelName, type, required, placeholder, pattern, namePattern}, index) => {
            const regex = new RegExp(namePattern!, "i");

            return (
                <div key={index} className="input-block">
                  <p>{labelName} {required ? "*" : ''}</p>
                  {
                    type === 'select' ?
                        <select
                            onChange={(e) => onOptionChange(e)}
                            name={name}
                            id={`${index}`}
                            required={required}
                        >
                          <option value="">{t('file-upload.select-file')}</option>
                          {buf.map((file: File, id: number) => {
                              return (
                                  <option
                                      key={id}
                                      {...(namePattern && regex.test(file.name) ? {selected: true} : {})}
                                  >
                                      {file.name}
                                  </option>
                              );
                          })}
                        </select>
                        : <input
                            className={`${theme}`}
                            type="text"
                            name={name}
                            placeholder={placeholder}
                            required
                            onChange={(e) => onOptionChange(e, false)}
                            pattern={pattern}
                        />
                  }
                </div>
            )
          })}
        </>
    );
  }

  return (
      <LoadingBackdrop isVisible={saving} transparent>
        <div className="dnd-bg">
          <div className="dnd-container">
            <div className="content-header">
              <span>{t('file-upload.project-creation-title')}</span>
          <img onClick={() => setVisible("")} src={windowClose} alt="close"/>
        </div>
        <div className="content">
          <div className="top-side">
            <div className="image">
              <img src={placeholder} alt=""/>
            </div>
            <div {...getRootProps({className: "dropzone"})}>
              <input {...getInputProps()} />
              <div onClick={open}>
                <Button text={t('file-upload.upload')} colorType="light"/>
              </div>
            </div>
          </div>
          <div className="bottom-side">
            <div className="left-side">
              <p className="files-list-header">{t('file-upload.files')}</p>
              <ul className="files-list">
                {filesList.map((file: File, index) => (
                    <div className="list-item-block" key={index}>
                        <li>
                          {file.name} - {file.size} bytes
                        </li>
                        <img
                            onClick={() => deleteFile(file.name)}
                            src={windowClose}
                            alt="close"
                        />
                      </div>
                  ))}
                </ul>
            </div>
            <div className="right-side">
              {InputSections()}
              <div className="button-block">
                <div className="transparent-button-block">
                  <Button
                      onClick={() => setVisible("")}
                      text={t('common.cancel')}
                      colorType="light"
                  />
                </div>
                <Button
                    onClick={createProject}
                    text={t('common.create')}
                    disabled={disabledSubmit}
                    colorType="dark"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </LoadingBackdrop>
  );
};

export default FilesUploader;
