import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {EntitiesListRowProps, EntitiesListItem} from "../EntitiesListRow";
import './entities-list.scss';
import {BaseEntity, BaseEntityNameLess} from "../../../api/entities/BaseEntity";
import {LoadingBackdrop} from "../../LoadingBackdrop/LoadingBackdrop";
import DeleteEntityModal from "../DeleteEntityModal/DeleteEntityModal";
import {ActionsProps} from "../EntitiesLoader/EntitiesLoader";
import {
    closestCenter,
    DndContext,
    MouseSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Button from "../../buttons/Button";
import type {DragEndEvent} from "@dnd-kit/core/dist/types";


type Props<T extends BaseEntityNameLess> = {
    loading?: boolean;
    entities: T[];
    selectedId?: string;
    actions?: React.ComponentType<ActionsProps>;
    loadDataFn: () => Promise<void>;
    draggable?: boolean;
    onUpdateListOrderFn?: (entities: T[]) => Promise<void>
    deleteFn: ({entity}: { entity: T }) => Promise<void>;
} & Omit<EntitiesListRowProps<T>, 'entity' | 'selected' | 'deleteFn'>;

const EntitiesList = <T extends BaseEntityNameLess>({
                                                        loading = false,
                                                        entities,
                                                        form,
                                                        onRowClick,
                                                        deleteFn,
                                                        getUrlFunc,
                                                        selectedId,
                                                        hasLink,
                                                        actions: Actions,
                                                        loadDataFn,
                                                        draggable,
                                                        onUpdateListOrderFn
                                                    }: Props<T>) => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showButtons, setShowButtons] = useState<boolean>(false);
    const [entity, setEntity] = useState<BaseEntity | BaseEntityNameLess | null>(null);
    const [items, setItems] = useState(entities);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
    );
    const {t} = useTranslation();

    useEffect(() => {
        if (entities.length && items.length) {
            const initialOrder = entities.every(({id}, ind) => {
                return items.length > ind ?  id === items[ind].id : false;
            });
            setShowButtons(!initialOrder);
        }
    }, [items, entities]);

    useEffect(() => {
        setItems(entities);
    }, [entities]);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            setItems((items) => {
                let oldIndex = 0, newIndex = 0;
                items.forEach(({id}, ind) => {
                    if (id === active.id) {
                        oldIndex = ind;
                    }
                    if (id === over.id) {
                        newIndex = ind;
                    }
                })

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }, [])

    // const refs = useMemo(() => entities.reduce((acc, entity) => {
    //     if (entity['id']) {
    //         acc[entity.id] = React.createRef();
    //     }
    //     return acc;
    // }, {}), [entities]);

    // useEffect(() => {
    //     if (selectedId && refs[selectedId].current) {
    //         refs[selectedId].current.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'start',
    //         });
    //     }
    // }, [selectedId]);

    const deleteBtnClickHandler = useCallback(({entity}: { entity: T }): void => {
        setEntity(entity);
        setShowDeleteModal(true);
    }, [])

    const revertListOrderHandler = useCallback(() => {
        setItems(entities)
    }, [entities])

    const updateListOrderHandler = useCallback(async () => {
        await onUpdateListOrderFn?.(items);
        setShowButtons(false);
    }, [items, onUpdateListOrderFn])

    return (
        <div className="entities-list" data-testid="entitiesList">
            <LoadingBackdrop isVisible={loading} transparent>
                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                    >
                        {
                            items?.map(entity =>
                                <EntitiesListItem
                                    key={entity.id}
                                    entity={entity}
                                    deleteFn={deleteBtnClickHandler}
                                    onRowClick={onRowClick as any}
                                    form={form}
                                    selected={entity.id === selectedId}
                                    // ref={refs[entity.id]}
                                    getUrlFunc={getUrlFunc as any}
                                    hasLink={hasLink}
                                    draggable={draggable}
                                    {...(Actions ? {
                                        actions: <Actions id={entity?.id} loadEntitiesFn={loadDataFn}/>
                                    } : {})}
                                />
                            )
                        }
                        {
                            showDeleteModal && entity && deleteFn
                                ? <DeleteEntityModal
                                    entity={entity}
                                    setEntity={setEntity}
                                    setModalShow={setShowDeleteModal}
                                    deleteFn={deleteFn as any}
                                />
                                : null
                        }
                    </SortableContext>
                </DndContext>
                {
                    showButtons
                        ? <div className="entities-list-order-buttons">
                            <Button
                                onClick={revertListOrderHandler}
                                text={t("entities-list.revert-btn")}
                                colorType="transparent"
                                testId="revertEntitiesOrderChangeBtn"
                            />
                            <Button
                                type="submit"
                                onClick={updateListOrderHandler}
                                text={t("entities-list.update-btn")}
                                colorType="dark"
                                testId="confirmEntitiesOrderChangeBtn"
                            />
                        </div>
                        : null
                }
            </LoadingBackdrop>
        </div>
    )
}

export default EntitiesList;