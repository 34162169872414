import React, {useEffect} from 'react';
import {useAuth} from "../../context/authContext";
import {useLocation, useNavigate} from "react-router-dom";
import SignOutLayout from "../SignOutLayout";
import SignInLayout from "./SignInLayout";


export const SignInPage = () => {
    const {signingIn, signingOut, token, runLogin} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate(location.state?.from ?? '/');
        }
    }, [token, location])

    if (signingOut) {
        return <SignOutLayout/>
    }

    return <SignInLayout signingIn={signingIn} runLogin={runLogin}/>;
}