import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectSelectedFeatures} from "../../../redux/selectors/selectors";
import {FieldWithLabel} from "../../../hoc/withClass";
import {FormFieldProps} from "../Form/Form";
import {CheckIcon} from "../../icons/icons/CheckIcon";
import {StopCircleArrowOutlineIcon} from "../../icons/icons/StopCircleArrowOutlineIcon";
import {IconButton} from "../../buttons/IconButton/IconButton";
import classNames from "classnames";
import {useTheme} from "../../../context/themeContext";
import '../GeometryField/geometry-form-field.scss';
import {clearedSelectedMapFeatures} from "../../../redux/showInfo/showInfoReducer";
import {useDispatch} from "react-redux";
import {useToastContext} from "../../../context/toastContext";
import {useTranslation} from "react-i18next";
import {setRedirectOnFeatureSelection} from "../../../redux/map/map-reducer";

interface Props extends FormFieldProps {
  onErrorMessage?: string;
  name: string;
  linkable?: boolean;
}

const RoadSegmentField = ({name, value = '', linkable = false, onChange, onErrorMessage, ...props}: Props) => {
  const [valueState, setValue] = useState(value);
  const [featureSelectionMode, setFeatureSelectionMode] = useState<boolean>(false);
  const selectedFeatures = useTypedSelector(selectSelectedFeatures);
  const ref = useRef<HTMLInputElement>(null);
  const {t} = useTranslation();
  const {addToast} = useToastContext();
  const {theme} = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(value);
  }, [value])

  useEffect(() => {
    if (featureSelectionMode) {
      addToast(t('road-network.select-segment-message'), "info");
    }
  }, [featureSelectionMode]);

  useEffect(() => {
    if (featureSelectionMode && selectedFeatures?.length) {
      // onChange event isn't triggered when input value is changed by state => trigger manually
      const event = new Event('change', { bubbles: true});
      onChange?.(event as any);

      setValue(selectedFeatures[0]?.id);
      setFeatureSelectionMode(false);
      //TODO use useLayersInteractivity.toggleRedirectOnFeatureSelection instead of
      dispatch(setRedirectOnFeatureSelection(true));
      dispatch(clearedSelectedMapFeatures());
    }
  }, [featureSelectionMode, selectedFeatures]);

  const className = classNames(
    'text-field-md',
    theme
  );

  const btnClickHandler = useCallback(() => {
    setFeatureSelectionMode(prevState => !prevState);
    //TODO use useLayersInteractivity.toggleRedirectOnFeatureSelection instead of
    dispatch(setRedirectOnFeatureSelection(featureSelectionMode));
  }, [featureSelectionMode])

  return (
    <div className="form-field-md">
      <div className="geometry-form-field">
        <input
          className={className}
          // onSubmitCapture={(e) => console.log("input.onSubmitCapture", e)}
          name={name}
          type="text"
          value={valueState}
          readOnly
          // onInvalid={onInvalid}
          onChange={onChange}
          ref={ref}
          //{...props}
          // disabled
        />
        <IconButton
          icon={featureSelectionMode ? CheckIcon : StopCircleArrowOutlineIcon}
          height={20}
          width={20}
          onClick={btnClickHandler}
          // disabled={disabled || !editMode}
          borderless
        />
      </div>
    </div>
  )
}

const Labeled = FieldWithLabel(RoadSegmentField);

export {Labeled, RoadSegmentField}

export default RoadSegmentField;