import * as React from "react";
import {Route} from "react-router-dom";
import LayersModePage from "./LayersModePage";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";

const KeplerLayers = React.lazy(() => import('../KeplerLayers/KeplerLayersModePage'));

export const useLayersRoutes = () => {
    return (
        <>
            <Route index element={<LayersModePage/>}/>
            <Route path="klayers" element={<LazyLoadingWrapper element={<KeplerLayers/>}/>}/>
        </>
    );
}