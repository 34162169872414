import React from "react";
import {Navigate, Route} from "react-router-dom";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";
import BreadcrumbedComponent from "../../components/BreadcrumbedComponent/BreadcrumbedComponent";
import EvChargersList from "./EvManagement/EvChargersList";
import ChargerEdit from "./EvManagement/ChargerEdit";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const EvInfrastructureModePage = React.lazy(() => import('./EvInfrastructureModePage'));

export const useEvInfrastructureRoutes = () => {
    return (
        <Route path="ev" element={<LazyLoadingWrapper element={<EvInfrastructureModePage/>}/>}>
            <Route index element={<Navigate to="chargers" replace/>}/>
            <Route
                element={<BreadcrumbedComponent/>}
                handle={{crumb: (data: any) => ({pathnamePostFix: 'chargers', name: 'Chargers', ...data})}}
            >
                <Route path="chargers">
                    <Route index element={<EvChargersList/>}/>
                    <Route path="new" element={<ChargerEdit/>}/>
                    <Route
                        path=":chargerId/edit"
                        element={<ChargerEdit/>}
                        loader={({params}) => params}
                        handle={{crumb: ({chargerId}) => ({id: chargerId})}}
                    />
                </Route>
            </Route>
            <Route
                path='layers'
                element={
                    <LayersListWrapper>
                        <LayersTimeSelectors/>
                    </LayersListWrapper>
                }
            />
        </Route>
    )
}