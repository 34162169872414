import React from "react";
import {ChangeEvent, FormEvent, useState} from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";


import "./select-box.scss";
import {FormFieldProps} from "../Form/Form";
import {FieldWithLabel} from "../../../hoc/withClass";
import classNames from "classnames";

export type Option = {
  value: string | number;
  caption?: string;
};

export type SelectFieldState = {
  valid?: boolean;
  errorMsg: string;
};

interface SelectBoxProps extends FormFieldProps {
  options: Option[];
  defaultOption?: string;
  defaultOptionDisabled?: boolean;
  name: string;
  linkable?: boolean;
}

const SelectBox = React.forwardRef(({
                                      options,
                                      defaultOption,
                                      defaultOptionDisabled = true,
                                      linkable = false,
                                      testId,
                                      ...props
                                    }: SelectBoxProps,
                                    ref: React.ForwardedRef<HTMLSelectElement>
) => {
  const [valueState, setValue] = useState(props.value);
  const [state, setState] = useState<SelectFieldState>({
    valid: undefined,
    errorMsg: "",
  });

  const [open, setOpen] = useState<boolean>(false);

  function onChange(event: ChangeEvent<HTMLSelectElement>) {
    event.preventDefault();

    const {validationMessage} = event.target;
    validate(validationMessage);

    setValue(event.target.value);
    props?.onChange?.({...event});
  }

  function onInvalid(event: FormEvent<HTMLSelectElement>) {
    event.preventDefault();

    const {validationMessage} = event.target as any;
    validate(validationMessage);
  }

  function validate(validationMessage: string) {
    const isValid = validationMessage === "";
    setState({valid: isValid, errorMsg: validationMessage});
  }

  function toggleArrow() {
    //TODO add outside click listener to toggle arrow
    setOpen(!open);
  }

  function drawOption(option: Option, ind: number) {
    return (
      <option key={`${option.value}${ind}`} value={option.value}>
        {option.caption ?? option.value}
      </option>
    );
  }

  function getDefaultOption(disabled: boolean) {
    if (!defaultOption) return null;
    return (
      <option disabled={disabled} value="">
        {defaultOption}
      </option>
    );
  }

  const className = classNames(
    'select-box-md',
    linkable && 'select-box-md_link'
  );

  return (
    <div className="form-field-md" style={{...props.style}}>
      <select
        {...props}
        value={valueState}
        onChange={onChange}
        onInvalid={onInvalid}
        className={className}
        onClick={toggleArrow}
        ref={ref}
        {...(testId ? {'data-testid': testId} : {})}
      >
        {getDefaultOption(defaultOptionDisabled)}
        {options.map((option, ind) => drawOption(option, ind))}
      </select>
      <ErrorMessage msg={state.errorMsg}/>
      {/*
      //TODO fix img click then show
      <img className="select-box-arrow" src={open ? iconUp : iconDown} alt="" />*/}
    </div>
  );
});

const Labeled = FieldWithLabel(SelectBox);

export {Labeled, SelectBox as Select};

export default SelectBox;
