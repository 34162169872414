import {configureStore} from '@reduxjs/toolkit'
import {enhanceReduxMiddleware} from "@kepler.gl/reducers";
import {rootReducer} from "./reducers/index";


const middlewares = enhanceReduxMiddleware([]);

// let composeEnhancers = compose;
// if (Window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
//     composeEnhancers = Window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         actionsBlacklist: [
//             '@@kepler.gl/MOUSE_MOVE',
//             '@@kepler.gl/UPDATE_MAP',
//             '@@kepler.gl/LAYER_HOVER'
//         ]
//     });
// }

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            //         // serializableCheck: {
            //         //     ignoredActions: [ActionTypes.LAYER_HOVER, ActionTypes.MOUSE_MOVE, ActionTypes.LOAD_MAP_STYLES, ActionTypes.UPDATE_MAP],
            //         // },
        }).concat(middlewares),
    // enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(composeEnhancers)
});

export default store;
