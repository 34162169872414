import {useEffect, useRef} from "react";
import {embedDashboard} from "@superset-ui/embedded-sdk";
import './side-panel-dashboards.scss';
import {
    DashboardEmbeddingResultEnvelopeEntity
} from "../../api/entities/local/replancity_DashboardEmbeddingResultEnvelope";


export type SidePanelDashboardType = Pick<DashboardEmbeddingResultEnvelopeEntity, 'link' | 'type' | 'dashboardSideBarID' | 'sideBarSecretCode'>;

export type WindowDashboardType = Pick<DashboardEmbeddingResultEnvelopeEntity, 'link' | 'type' | 'dashboardExternalID' | 'secretCode'>;

const SuperSetDashboardGenerator = (props: SidePanelDashboardType | WindowDashboardType) => {
    const ref = useRef();

    useEffect(() => {
        if (props) {
            (async function () {
                // const {dashboardExternalID, dashboardSideBarID, link, secretCode, sideBarSecretCode} = props;

                await embedDashboard({
                    id: (props['dashboardExternalID'] ?? props['dashboardSideBarID'])!,
                    supersetDomain: props.link,
                    mountPoint: ref.current!,
                    fetchGuestToken: async () => props['secretCode'] ?? props['sideBarSecretCode'],
                    dashboardUiConfig: {
                        hideTitle: true,
                        filters: {
                            visible: false,
                            expanded: false
                        }
                    },
                    // debug: true
                });
            }());
        }
    }, [props]);


    return (
        <div
            className="sup-set-dashboard"
            ref={ref as any}
        ></div>
    )
}

export default SuperSetDashboardGenerator;