import {ApiError} from "../api/RestClient";
import {DataById} from "../redux/map/types";
import {SimulationState} from "../api/entities/replancity_RunnedAlgorithm";
import {MetadataPropertyEntity} from "../api/entities/replancity_MetadataProperty";
import {FormDataObject} from "../components/Entities/EntityForm";
import {RunState} from "../api/entities/replancity_RunTask";
import {themeColors} from "../styles/theme";
import {TFunction} from "react-i18next";
import {ENV} from "./env";


export const isReplanApp = (url: string) => {
  switch (url) {
    case 'http://localhost:3000':
      return true;
    case ENV.REACT_APP_PLANSIM_URL:
      return false;
    default:
      return true;
  }
}

export const formatFloat = (val: number) => {
  switch (val % 1) {
    case 0:
      return val;
    default:
      return val.toFixed(2);
  }
}

export const formatNumber = (val: number | string) => {
  switch (typeof val) {
    case "number":
      return formatFloat(val);
    default:
      return val;
  }
}

export const isErrorResponse = (resp: object): resp is ApiError => {
  return resp && typeof resp === 'object' && 'error' in resp; // || !resp
}

export const getLocalStorageItem = (key: string) => safelyParseJson(localStorage.getItem(key) || '');

export const getSecondsFromTimeStr = (time: string) => parseInt(time.split(':')[0]) * 3600;

export const getObjectByPropertyName = (objectArr: any[]): DataById<any> => {
  return objectArr.reduce((acc, cur) => {
    const name = cur['name'];
    if (!acc[name]) {
      acc[name] = cur;
    }
    return acc;
  }, {});
}

export const combineProjectsDatasets = <T extends Record<string, unknown>>(data: T[]): Partial<Record<keyof T, any[]>> => {
  return data?.reduce((acc, cur) => {
    for (const [key, val] of Object.entries(cur)) {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(val);
    }
    return acc;
  }, {});
}

export const isUuidString = (id: string | undefined) => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return !!id?.match(pattern);
}

export const isProjectSimulationInProgress = (simulationState: SimulationState) => {
  const IN_PROGRESS_PROJECT_SIMULATIOM_STATES = ['', SimulationState.IN_PROGRESS, SimulationState.PREPARE_MODEL];
  return IN_PROGRESS_PROJECT_SIMULATIOM_STATES.includes(simulationState);
}

export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export const safelyParseJson = (jsonString: string) => {
  try {
    return  JSON.parse(jsonString);
  } catch {
    return jsonString;
  }
}

export const safelyStringifyJson = (json: any) => {
  try {
    return  JSON.stringify(json);
  } catch {
    return json;
  }
}

export const getFilledFieldsObjOnFormSubmit = (event: any): FormDataObject => {
  const formData = new FormData(event.target);
  const formDataObj = {};
  for (const [key, val] of formData) {
    if (val) {
      formDataObj[key] = val; // safelyParseJson(val as string);
    }
  }

  return formDataObj;
}

export const areAllRequiredFormFieldsFilled = (
    formDataObj: FormDataObject,
    metaData: MetadataPropertyEntity[]
): boolean => {
  for (const {name, mandatory} of metaData) {
    if (!formDataObj[name] && mandatory) {
      return false;
    }
  }

  return true;
}

export const isFunction = (fn: any): fn is () => unknown => {
    return fn && {}.toString.call(fn) === '[object Function]';
}

export const getSimulationState = (state: SimulationState, t: TFunction<string, unknown>): string => {
  switch (state) {
    case SimulationState.NEVER:
      return t('simulation.never-started');
    case SimulationState.PREPARE_MODEL:
      return t('simulation.prepare-model');
    case SimulationState.IN_PROGRESS:
      return t('simulation.in-progress');
    case SimulationState.COMPLETE:
      return t('simulation.completed');
    case SimulationState.TERMINATING:
      return t('simulation.terminating');
    case SimulationState.CANCELED:
      return t('simulation.canceled');
    case SimulationState.OUTDATED:
      return t('simulation.outdated');
    case SimulationState.ERROR:
      return t('simulation.error');
    case SimulationState.EV_CALCULATION:
      return t('simulation.ev-calculation-state');
    case SimulationState.CALIBRATION:
      return t('simulation.calibration-state');
    case SimulationState.POSTANALYSIS:
      return t('simulation.postanalysis-state');
    case SimulationState.POSTANALYSIS_ERROR:
      return t('simulation.postanalysis-error-state');
    case SimulationState.EV_CALCULATION_ERROR:
      return t('simulation.ev-calculation-error-state');
    case SimulationState.CALIBRATION_ERROR:
      return t('simulation.calibration-error-state');
    case SimulationState.QUEUE:
      return t('simulation.queue-state');
    default:
      return state;
  }
}

export const getSimulationStateColor = (state: SimulationState) => {
  switch (state) {
    case SimulationState.NEVER:
      return themeColors.grey3;
    case SimulationState.OUTDATED:
    case SimulationState.CANCELED:
    case SimulationState.ARCHIVED:
      return themeColors.grey;
    case SimulationState.IN_PROGRESS:
    case SimulationState.PREPARE_MODEL:
    case SimulationState.TERMINATING:
    case SimulationState.EV_CALCULATION:
    case SimulationState.CALIBRATION:
    case SimulationState.POSTANALYSIS:
    case SimulationState.RESTARTING:
    case SimulationState.QUEUE:
      return themeColors.statusInProgress;
    case SimulationState.COMPLETE:
      return themeColors.statusCompleted;
    case SimulationState.ERROR:
    case SimulationState.POSTANALYSIS_ERROR:
    case SimulationState.EV_CALCULATION_ERROR:
    case SimulationState.CALIBRATION_ERROR:
      return themeColors.invalidColor1;
    default:
      return '';
  }
}

export const getRunStateColor = (state: RunState): string => {
  switch (state) {
    case RunState.COMPLETE:
      return themeColors.statusCompleted;
    case RunState.IN_PROGRESS:
      return themeColors.statusInProgress;
    case RunState.ERROR:
      return themeColors.invalidColor1;
    default:
      return '#fff';
  }
}