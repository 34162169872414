import mapboxgl, {IControl} from "mapbox-gl";
import {DataById, NamedLayer} from "../../../../../redux/map/types";
import {isLayerVisibleOnMap, layerHasDontShowState, LINE_WIDTH_AND_ZOOM_ARR} from "../../../../../utils/mapUtils";
import {isUuidString} from "../../../../../utils/utils";


export type LineWidthMapControlOption = DataById<{ value: string; selected?: boolean; }>;

export class MapboxGLSelectorControlClass implements IControl {
    private readonly _className: string;
    private readonly _title: string;
    // private readonly _icon: SVGElement | undefined;
    private readonly _options: LineWidthMapControlOption;
    private readonly _eventHandler: (event: any) => void;
    private _select: HTMLSelectElement | undefined;
    private _container: HTMLDivElement | undefined;
    private _map: mapboxgl.Map | undefined;

    constructor({
                    className = "",
                    title = "",
                    // icon,
                    eventHandler,
                    options
                }) {
        this._className = className;
        this._title = title;
        // this._icon = icon;
        this._eventHandler = eventHandler;
        this._options = options;
    }

    onAdd(map: mapboxgl.Map) {
        this._select = document.createElement("select");
        this._select.className = `mapboxgl-ctrl-icon ${this._className}`;
        this._select.title = this._title;

        this._select.onclick = (event: MouseEvent) => {
            this._select?.classList.toggle('mapboxgl-ctrl-icon_active');
            // this._eventHandler(event);
        };
        this._select.onchange = (event: Event) => {
            const {target} = event;

            if (target) {
                const {value} = <HTMLSelectElement>target;

                const curZoom = map.getZoom();
                let width = 1;
                for (let i = 0; i < LINE_WIDTH_AND_ZOOM_ARR.length; i++) {
                    if (i % 2 === 0) {
                        if (curZoom < LINE_WIDTH_AND_ZOOM_ARR[i]) {
                            break;
                        }
                    } else {
                        width = LINE_WIDTH_AND_ZOOM_ARR[i] as any;
                    }
                }

                const layers = map.getStyle().layers.filter(layer => isUuidString(layer.id) && isLayerVisibleOnMap(layer as NamedLayer) && layer.type === 'line');
                layers.forEach(layer => {
                    map.setPaintProperty(layer.id, 'line-width', width * Number(value));
                });
            }

        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._select);

        Object.entries(this._options).forEach(([name, {value, selected}]) => {
            const option = document.createElement("option");
            option.text = name;
            option.value = value;
            option.selected = !!selected;

            this._select?.add(option);
        })

        return this._container;
    }

    onRemove(map: mapboxgl.Map) {
        this._select = undefined;
        this._container?.parentNode?.removeChild(this._container);
        this._map = undefined;
    }
}