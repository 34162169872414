import * as React from "react";
import {Route} from "react-router-dom";
import MaasZonesList from "./MaasManagement/MaasList";
import MaasZoneEdit from "./MaasManagement/MaasZoneEdit";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";


const MaasEditor = React.lazy(() => import('./MaasModePage'));

export const useMaasRoutes = () => {
    return (
        <Route
            path="maas"
            element={<LazyLoadingWrapper element={<MaasEditor/>}/>}
            handle={{crumb: (data: any) => ({name: 'Maas zones', ...data})}}
        >
            <Route index element={<MaasZonesList/>}/>
            <Route path="new" element={<MaasZoneEdit/>}/>
            <Route
                path=":zoneId/edit"
                element={<MaasZoneEdit/>}
                loader={({params}) => params}
                handle={{crumb: ({zoneId}) => ({id: zoneId})}}
            />
            <Route
                path='layers'
                element={
                    <LayersListWrapper>
                        <LayersTimeSelectors/>
                    </LayersListWrapper>
                }
            />
        </Route>
    )
}