import React, {Dispatch, SetStateAction} from "react";
import {ProjectEntity} from "../api/entities";
import moment from 'moment';
import {SimulationState} from "../api/entities/replancity_RunnedAlgorithm";
import {useTranslation} from "react-i18next";
import {useTheme} from "../context/themeContext";
import {ImagePlaceholder} from "./icons/icons/ImagePlaceholder";
import {themeColors} from "../styles/theme";
import {getSimulationState, getSimulationStateColor} from "../utils/utils";
import {DeleteIcon} from "./icons/icons/DeleteIcon";
import {IconButton} from "./buttons/IconButton/IconButton";
import {useNavigate} from "react-router-dom";


interface Props {
  projects: Array<ProjectEntity>;
  onModelClickHandler: any;
  setSelectedProjectId: Dispatch<SetStateAction<string>>;
  setPopupVisible: Dispatch<SetStateAction<string>>;
}

const ProjectsTable: React.FC<Props> = ({projects, onModelClickHandler, setSelectedProjectId, setPopupVisible}) => {
  const {t} = useTranslation();
  const {theme} = useTheme();
  const navigate = useNavigate();

  const handleRowClick = (project: ProjectEntity) => {
    const {id, projectName} = project;
    onModelClickHandler(projectName, id);
    navigate(`./${id}`);
  }

  const projectDeleteHandler = async (event, id: string) => {
    event.stopPropagation();
    setSelectedProjectId(id);
    setPopupVisible('delete');
  }

  return (
    <tbody>
    {projects.map((el) => {
      return (
        <tr
            className={`project-row ${theme}`}
            key={el.id}
            onClick={() => handleRowClick(el)}
            data-testid={`project-row_${el.id}`}
        >
          <td>
            <div className={`project-row__title ${theme}`}>
              <ImagePlaceholder
                width={32}
                height={32}
                color={theme === 'light' ? themeColors.stroke : themeColors.navyBlue}
                backgroundColor={theme === 'light' ? themeColors.grey1 : themeColors.grey3}
              />
              <p>{el.projectName}</p>
            </div>
          </td>
          <td>
            <div className={`project-row__time center ${theme}`}>
              <p>
                {moment(el.updateTs).format('YYYY-MM-DD HH:mm:ss')}
              </p>
            </div>
          </td>
          <td>
            <div
              className={`project-row__simulation-state center ${theme}`}
            >
              <span
                className="simulation-state"
                style={{'background': getSimulationStateColor(el.simulationState)}}
              >
                  {getSimulationState(el.simulationState as SimulationState, t)}
              </span>
            </div>
          </td>
          <td>
            <div className={`project-row__controls center ${theme}`}>
              <IconButton
                icon={DeleteIcon}
                // iconColor={getIconColor(theme, viewType === ViewType.GRID)}
                // backgroundColor={getIconBackgroundColor(theme, viewType === ViewType.GRID)}
                // isActive={viewType === ViewType.GRID}
                onClick={(event) => projectDeleteHandler(event, el.id)}
                data-testid={`delete_project_${el.id}`}
              />
            </div>
          </td>
          <td>
            <div className={`project-row__empty right ${theme}`}></div>
          </td>
        </tr>
      );
    })
    }
    </tbody>
  );
};
export default ProjectsTable;
