import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import './error-component.scss';
import {ApiError} from "../../api/RestClient";

type Props = {
    children?: React.ReactNode;
    error?: ApiError | null;
    warning?: string;
}

const ErrorComponent: React.FC<Props> = ({children, error, warning}) => {

    if (error) {
        return (
            <div className="error-wrapper">
                <ErrorOutlineIcon
                    color="secondary"
                    sx={{fontSize: '50px'}}
                />
                <div className="error-message">
                    {error.error_description}
                </div>
            </div>
        )
    }
    if (warning) {
        return (
            <div className="error-wrapper">
                <WarningAmberIcon
                    color="warning"
                    sx={{fontSize: '50px'}}
                />
                <div className="error-message">
                    {warning}
                </div>
            </div>
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export default ErrorComponent;