import {useCallback, useEffect} from "react";
import {useToastContext} from "../context/toastContext";
import {useTranslation} from "react-i18next";


const IMAGE_LINK = `${process.env.PUBLIC_URL}/speed_test.jpg`;

export const useInternetSpeedMeasurer = () => {
    const downloadSize = 65536; // size in bytes
    const {t} = useTranslation();
    const {addToast} = useToastContext();

    const displaySpeed = useCallback((time_start: number, time_end: number) => {
        const timeDuration = (time_end - time_start) / 1000;
        const loadedBits = downloadSize * 8;
        const speed: number = +(loadedBits / timeDuration / 1024 / 1024).toFixed(2);

        console.log(`==Your internet connection speed is: ${speed} Mbps`);
        if (speed < 1) {
            addToast(t('common.low-internet-speed-toast'), 'info');
        }
    }, [])

    useEffect(() => {
        const downloadImgSrc = new Image();
        downloadImgSrc.src = IMAGE_LINK;

        const time_start = new Date().getTime();

        downloadImgSrc.onload = function () {
            const time_end = new Date().getTime();
            displaySpeed(time_start, time_end);
        };
    }, [displaySpeed, IMAGE_LINK])

    return {}
}
