import React, {
  FormEvent,
  forwardRef,
  PropsWithChildren,
  useCallback
} from "react";
import "./form.scss";
import Button from "../../buttons/Button";


type FormProps = React.AllHTMLAttributes<HTMLFormElement> & {
  submitBtnTitle?: string;
  submitBtnTestId?: string;
  testId?: string;
};

export type FormFieldProps = React.AllHTMLAttributes<HTMLElement> & {
  label?: string;
  required?: boolean;
  hidden?: boolean;
  name: string;
  fieldGroupClassName?: string;
  value?: any;
  testId?: string;
};

const Row = React.forwardRef((props: React.PropsWithChildren<any>, ref: React.ForwardedRef<HTMLDivElement>) => {
  return <div className="form-edit-row" ref={ref}>{props.children}</div>;
});

const Form = forwardRef<HTMLFormElement, PropsWithChildren<FormProps>>((
  props,
  ref?
) => {
  const {name, onChange, testId, onSubmit, submitBtnTitle, submitBtnTestId, ...rest} = props;

  const onSubmitHandler = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit?.(event);
  }, [onSubmit])

  return (
    <form
      name={name}
      className="form-edit"
      onSubmit={onSubmitHandler}
      onChange={onChange}
      ref={ref}
      {...(testId ? {'data-testid': testId} : {})}
      {...rest}
      // onSubmitCapture={(e) => console.log("onSubmitCapture", e)}
      // onInvalid={(e) => console.log("onInvalid", e)}
      // onInvalidCapture={(e) => console.log("onInvalidCapture", e)}
    >
      <div className="form-edit_body">
        {props.children}
      </div>
      <div>
        <Button
        type="submit"
        text={submitBtnTitle || "Submit"}
        disabled={props?.disabled}
        colorType="dark"
        {...(submitBtnTestId ? {'testId': submitBtnTestId} : {})}
      />
      </div>
    </form>
  );
});

export {Row, Form};
