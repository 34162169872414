import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import {ApiError} from "../api/RestClient";
import {useDispatch} from "react-redux";
import {projectsApi} from "../api/projectsApi";
import {isErrorResponse, isUuidString} from "../utils/utils";
import {addedProjectId, addedProjects} from "../redux/reducers/projectsReducer";
import {useNavigate, useParams} from "react-router-dom";
import {useTypedSelector} from "../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectsById} from "../redux/selectors/selectors";
import {LoadingComponent} from "../components/LoadingComponent/LoadingComponent";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";
import {useToastContext} from "./toastContext";
import {useTranslation} from "react-i18next";
import {useAllowedModes} from "../hooks/projects/useAllowedModes";
import {clearedSelectedMapFeatures} from "../redux/showInfo/showInfoReducer";


export enum ViewType {
    LINEAR,
    GRID
}

type ProjectState = {
    projectId?: string;
    loadProjects: () => Promise<void>;
    deleteProject: (id: string) => Promise<string>;
    error?: ApiError | undefined;
    projectModesLoaded: boolean;
    setProjectModesLoaded: Dispatch<boolean>;
    modesError?: ApiError;
    loadProjectModes: (projectId: string) => Promise<void>;
    projectsListViewType: ViewType;
    setProjectsListViewType: Dispatch<SetStateAction<ViewType>>;
    clearProjectData: () => void;
};

const ProjectContext = createContext<ProjectState>({
    projectId: '',
    loadProjects: () => {
        return Promise.resolve();
    },
    deleteProject: (id: string) => {
        return Promise.resolve('');
    },
    projectModesLoaded: false,
    setProjectModesLoaded: () => false,
    loadProjectModes: () => {
        return Promise.resolve();
    },
    projectsListViewType: ViewType.LINEAR,
    setProjectsListViewType: () => ViewType.LINEAR,
    clearProjectData: () => {
        return;
    }
});

const useProjectContext = () => useContext<ProjectState>(ProjectContext);

const ProjectProvider = ({children}: PropsWithChildren) => {
    const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false);
    const [projectsListViewType, setProjectsListViewType] = useState<ViewType>(ViewType.LINEAR);
    const [projectsError, setProjectsError] = useState<ApiError>();
    const {projectId: projectIdParam, test} = useParams<{ projectId?: string; test: any }>();
    const projectsById = useTypedSelector(selectProjectsById);
    const currentProjectId = useTypedSelector(selectCurrentProjectId);
    const {
        projectModesLoaded,
        error: modesError,
        isModeAllowed,
        setProjectModesLoaded,
        loadProjectModes
    } = useAllowedModes();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    useEffect(() => {
        (async function () {
            await loadProjects();
        })();
    }, []);

    useEffect(() => {
        if (projectIdParam && projectsLoaded) {
            if (!isUuidString(projectIdParam) || !projectsById[projectIdParam]) {
                addToast(`${t('projects-main.project-doesnt-exist')}`, 'info');
                navigate('/', {replace: true});
            }

            dispatch(addedProjectId(projectIdParam));

            if (!projectModesLoaded) {
                (async function () {
                    await loadProjectModes(projectIdParam)
                }());
            }
        }
    }, [projectIdParam, projectsLoaded, projectsById]);

    useEffect(() => {
        if (projectModesLoaded && !isModeAllowed) {
            navigate('/', {replace: true});
        }
    }, [projectModesLoaded, isModeAllowed])

    const loadProjects = useCallback(async () => {
        const projects = await projectsApi.getProjects();

        if (isErrorResponse(projects)) {
            setProjectsError(projects);
        } else {
            const projectsById = projects.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {});

            dispatch(addedProjects(projectsById));
        }

        setProjectsLoaded(true);
    }, [])

    const deleteProject = async (id: string): Promise<string> => {
        return await projectsApi.deleteProject(id);
    }

    const clearProjectData = useCallback(() => {
        dispatch(addedProjectId());
        dispatch(clearedSelectedMapFeatures());
        setProjectModesLoaded(false);
    }, [])

    const value = {
        projectId: currentProjectId,
        loadProjects,
        deleteProject,
        projectModesLoaded,
        setProjectModesLoaded,
        modesError,
        loadProjectModes,
        projectsListViewType,
        setProjectsListViewType,
        clearProjectData
    };

    return (
        <ProjectContext.Provider value={value}>
            <LoadingComponent isLoading={!projectsLoaded}>
                <ErrorComponent error={projectsError}>
                    {children}
                </ErrorComponent>
            </LoadingComponent>
        </ProjectContext.Provider>
    );
};

export {ProjectProvider, useProjectContext};
