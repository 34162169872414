import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import {PresetsProvider} from "../../context/presetsContext";
import {useProjectContext} from "../../context/ProjectContext";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import {LoadingComponent} from "../../components/LoadingComponent/LoadingComponent";


const ProjectView = () => {
    const {projectId, projectModesLoaded, modesError, setProjectModesLoaded, clearProjectData} = useProjectContext();

    useEffect(() => {
        return () => {
            clearProjectData();
        }
    }, []);

    return (
        <ErrorComponent error={modesError}>
            <LoadingComponent isLoading={!projectId || !projectModesLoaded}>
                <PresetsProvider>
                    <Outlet/>
                </PresetsProvider>
            </LoadingComponent>
        </ErrorComponent>
    );
};

export default ProjectView;
