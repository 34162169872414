import React, {useEffect, useState} from "react";
import {FieldWithLabel} from "../../../hoc/withClass";
import {FormFieldProps} from "../Form/Form";
import './checkbox-field.scss';
import classNames from "classnames";


export interface CheckboxFieldProps extends FormFieldProps {
    linkable?: boolean;
    alignCheckboxRight?: boolean;
}

const CheckboxField = ({
                           name,
                           value,
                           linkable = false,
                           onChange,
                           style,
                           alignCheckboxRight = false,
                           testId,
                           ...props
                       }: CheckboxFieldProps) => {
    const [checkedState, setChecked] = useState<boolean>(!!value && value !== 'false');

    useEffect(() => {
        setChecked(!!value && value !== 'false');
    }, [value]);

    const onChangeHandler = (e) => {
        setChecked(e.target.checked);
        onChange?.(e);
    }

    const formFieldClassNames = classNames(
        'form-field-md',
        alignCheckboxRight && 'form-field-md_align-right'
    );

    const className = classNames(
        linkable && 'checkbox-field_link'
    );

    return (
        <div
            className={formFieldClassNames}
            style={style?.minWidth ? {minWidth: style?.minWidth} : {}}
        >
            <input
                type="hidden"
                name={name}
                value="false"
            />
            <input
                type="checkbox"
                name={name}
                className={className}
                value="true"
                checked={checkedState}
                onChange={onChangeHandler}
                {...(testId ? {'data-testid': testId} : {})}
                {...props}
            />
        </div>
    )
}

const Labeled = FieldWithLabel<CheckboxFieldProps>(CheckboxField);

export {
    Labeled
};

export default CheckboxField;