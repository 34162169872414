import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useTheme} from "../../../../context/themeContext";
import {removeEntity, setEntity} from "../../../../redux/entity/entity-reducer";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectSelectedFeatures} from "../../../../redux/selectors/selectors";
import EntityForm, {FormDataObject} from "../../../../components/Entities/EntityForm";
import {LoadingComponent} from "../../../../components/LoadingComponent/LoadingComponent";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {isErrorResponse} from "../../../../utils/utils";
import {useToastContext} from "../../../../context/toastContext";
import './group-selection.scss';


const GroupSelection = () => {
    const entityName = 'replancity_RoadInfrastructureUpdateSegment';
    const selectedFeatures = useTypedSelector(selectSelectedFeatures);
    const [loading, setLoading] = useState<boolean>(false);
    const {
        loading: isSegmentLoading,
        entity,
        metadata,
        loadData,
        groupSaveFn,
    } = useEntityLoader<any>({entityName});

    const {theme} = useTheme();
    const {addToast} = useToastContext();
    const {t} = useTranslation();
    const dispatch = useDispatch();


    useEffect(() => {
        (async function () {
            await loadData({entityName});
        }());

        return () => {
            dispatch(removeEntity(entityName));
        }
    }, []);

    const changeFormHandler = useCallback((formDataObj: { [key: string]: string | number }) => {
        //TODO this is excessive here to use Redux to check showIf for all fields.
        dispatch(setEntity({entityName, entity: {...entity, ...formDataObj}}));
    }, [])

    const submitFn = useCallback(async (formDataObj: FormDataObject) => {
        setLoading(true);

        const segmentIds = selectedFeatures.map(({id}) => id);
        const resp = await groupSaveFn({
                entityName,
                ids: segmentIds,
                data: formDataObj,
            }
        );

        if (!isErrorResponse(resp)) {
            addToast(`${t('common.save-successful')}`, 'success');
        }

        setLoading(false);

        return resp;
    }, [selectedFeatures, entityName])

    return (
        <div className="group-selection">
            <p data-testid="groupSelectionSelectedNum">{t('road-network.group-selection-title', {numItems: selectedFeatures.length})}</p>
            <p className="description">{t('road-network.group-selection-description')}</p>
            <LoadingComponent isLoading={isSegmentLoading || loading}>
                {
                    selectedFeatures.length
                        ? <EntityForm
                            loading={isSegmentLoading || loading}
                            entity={entity}
                            metadata={metadata}
                            changeFormFn={changeFormHandler}
                            saveFn={submitFn}
                        />
                        : null
                }
            </LoadingComponent>
        </div>
    )
}

export default GroupSelection;