import React, {useEffect, useState} from "react";
import {AuthPagesWrapper} from "../components/AuthPagesWrapper/AuthPagesWrapper";
import {Button, Divider, Grid, TextField, Typography} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {themeColors} from "../styles/theme";
import {useTranslation} from "react-i18next";
import CheckEmailPage from "./CheckEmailPage/CheckEmailPage";
import {LoadingBackdrop} from "../components/LoadingBackdrop/LoadingBackdrop";
import BackToSignInButton from "../components/BackToSignInButton/BackToSignInButton";
import {useAuth} from "../context/authContext";
import {useNavigate} from "react-router-dom";


type FormValuesType = {
  email: string,
}

export const ResetPassWordPage = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [requestedPasswordChange, setPasswordChangeRequest] = useState<boolean>(false);
  const {handleSubmit, control} = useForm<FormValuesType>();
  const {token} = useAuth();
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/', {state: {from: location.pathname}});
    }
  }, [token]);

  const onSubmit: SubmitHandler<FormValuesType> = async ({email}) => {
    // await runLogin(email, password)

    setEmail(email);
    setLoading(true);

    //TODO add async request

    setPasswordChangeRequest(true);
    setLoading(false);
  };

  if (requestedPasswordChange) {
    return <CheckEmailPage email={email}/>
  }

  return (
    <LoadingBackdrop isVisible={loading} transparent>
      <AuthPagesWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography component="h1" variant="h5">
              {t('reset-password-form.title')}
            </Typography>
            <Typography component="p">
              {t("reset-password-form.message")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                rules={{required: true}}
                render={({field, fieldState, formState}) =>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={t('reset-password-form.email-label')}
                    autoFocus
                    onChange={field.onChange}
                    sx={{background: themeColors.white}}
                  />}
              />
              <Divider/>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{my: 2}}
              >
                <BackToSignInButton title={t('reset-password-form.back-to-login-link')} />
                <Button
                  type="submit"
                  variant="contained"
                  // sx={{mt: 3, mb: 2}}
                  style={{background: themeColors.purple}}
                >
                  {t('reset-password-form.button')}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </AuthPagesWrapper>
    </LoadingBackdrop>
  );
}