import {useEffect, useState} from "react";
import {EntityMapLayer} from "../../api/entities/replancity_MapLayer";
import {createLayer, toSourceItem} from "../../utils/mapUtils";
import {addedLayers, addedSources, addedSourceData} from "../../redux/map/map-reducer";
import {projectsApi} from "../../api/projectsApi";
import {isErrorResponse} from "../../utils/utils";
import {useDispatch} from "react-redux";


export const useFastProjectGenerationZones = () => {
  const [zonesLoading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fastProjectGenerationZoneLayer: EntityMapLayer = {
      id: 'default',
      type: 'POLYGON',
      displayName: 'Default',
      viewName: 'Default',
      entityName: '',
      _entityName: '',
      needAlgorithmId: false,
      showByDefault: true,
      order: 0,
      opacity: 0.1,
      dayTimeDependent: false,
      clusterize: false,
      clickable: false,
      updateOnFeatureSelection: false,
      group: '',
      hasBalloon: false
    };
    const source = toSourceItem(fastProjectGenerationZoneLayer);
    const layer = createLayer(source);
    dispatch(addedSources(source));
    dispatch(addedLayers(layer));

    (async function () {
      setLoading(true);

      const resp = await projectsApi.getFastGenerationZones();

      if (resp && !isErrorResponse(resp)) {
        dispatch(addedSourceData({
          sourceId: 'default',
          data: resp,
        }));
      }

      setLoading(false);
    }());
  }, [])

  return {zonesLoading}
}