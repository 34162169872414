import {useControl} from "react-map-gl";
import {MapContextValue} from "react-map-gl/dist/esm/components/map";
import {ControlPosition} from "react-map-gl/src/types";
import {LineWidthMapControlOption, MapboxGLSelectorControlClass} from "./MapboxGLSelectorControlClass";


type Props = {
    title: string;
    eventHandler?: (event: any) => void;
    position: ControlPosition;
    className?: string;
    icon?: string;
    options: LineWidthMapControlOption;
}

const LineWidthSelectorControl = ({title, eventHandler, position, className, icon, options}: Props) => {
    useControl<any>(
        ({map}: MapContextValue) => {
            return new MapboxGLSelectorControlClass({
                className,
                title,
                // icon,
                eventHandler,
                options
            })
        },
        {
            position
        }
    );

    return null;
}

export default LineWidthSelectorControl;