import React, {memo, useMemo} from "react";
import DropDown, {OptionsAlign} from "../DropDown/DropDown";
import {themeColors} from "../../styles/theme";
import {useTranslation} from "react-i18next";
import {CompletedIcon} from "../icons/icons/statuses/CompletedIcon";
import {InProcessIcon} from "../icons/icons/statuses/InProcessIcon";
import {ErrorStatusIcon} from "../icons/icons/statuses/ErrorStatusIcon";
import {RunState} from "../../api/entities/replancity_RunTask";
import {getRunStateColor} from "../../utils/utils";
import {DropDownOptionType} from "../DropDown/Option";
import {usePresetsContext} from "../../context/presetsContext";


const getIconByState = (state: RunState): React.ReactElement | undefined => {
    switch (state) {
        case 'IN_PROGRESS': {
            return <InProcessIcon width={18} color={getRunStateColor(state)}/>
        }
        case 'COMPLETE': {
            return <CompletedIcon width={18} color={getRunStateColor(state)}/>
        }
        case 'ERROR': {
            return <ErrorStatusIcon width={18} color={getRunStateColor(state)}/>
        }
    }
}

const RunSelector = memo(() => {
    const {selectedRunId, runs, selectRun} = usePresetsContext();
    const {t} = useTranslation();

    const runsWithIcons: DropDownOptionType[] = runs.map(run => ({
        ...run,
        ...(run.state ? {rightIcon: getIconByState(run.state)} : {}),
        onClick: () => selectRun(run.id)
    }));

    const selectedRunIndex = useMemo(() => runs.findIndex(({id}) => id === selectedRunId), [runs, selectedRunId]);

    return (
        <>
            {
                runs.length ?
                    <DropDown
                        selectedOptionInd={selectedRunIndex}
                        options={runsWithIcons}
                        optionsAlign={OptionsAlign.LEFT}
                        // disabled={allowedModesDontExist}
                        titleColorLight={themeColors.navyBlue}
                        titleColorDark={themeColors.white}
                        optionColorLight={themeColors.navyBlue}
                        optionColorDark={themeColors.white}
                        iconColorLight={themeColors.white}
                        iconColorDark={themeColors.navyBlue}
                        iconRectangleWidth={35}
                        tooltip={t('header.select-run')}
                        maxWidth="300px"
                    />
                    : null
            }
        </>
    )
})

export default RunSelector;