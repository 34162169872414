import {useCallback, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId,} from "../../redux/selectors/selectors";
import {projectsApi} from "../../api/projectsApi";
import {SimulationState} from "../../api/entities/replancity_RunnedAlgorithm";
import {useDispatch} from "react-redux";
import {isErrorResponse} from "../../utils/utils";
import {addedLayerSimulationState} from "../../redux/map/map-reducer";
import {entityApi} from "../../api/entityApi";
import {ProjectRunProcessView} from "../../api/entities/replancity_Project";
import {ENV} from "../../utils/env";
import {usePresetsContext} from "../../context/presetsContext";


export interface SimulationStateData {
    simulationState: SimulationState;
    simulationProgress: number;
}

interface SimulationStateLoader {
    simulation: SimulationStateData;
    startSimulation: (data: any) => Promise<void>;
    restartSimulation: () => Promise<any>;
    getSimulationOptions: () => Promise<ProjectRunProcessView>;
}

type Props = {
    simulationCompletedFn?: () => Promise<void>;
}

const useSimulationStateLoader = ({simulationCompletedFn}: Props): SimulationStateLoader => {
    const entityName = 'replancity_Project';
    const [simulation, setSimulation] = useState<SimulationStateData>('' as any);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const {selectedRunId, loadRuns, selectRun} = usePresetsContext();
    const sseConnectionRef = useRef<EventSource | null>(null);
    const sseLayersConnectionRef = useRef<EventSource | null>(null);
    const sseConnectionAttemptsRef = useRef<number>(0);
    const simulationStarted = useRef<boolean>(false);
    const abortControllerRef = useRef(new AbortController());
    const dispatch = useDispatch();

    const sseSimulationMsgHandler = useCallback((event) => {
        try {
            const data = JSON.parse(event.data);
            const {runId, state, percentage, iteration, error, details} = data;

            setSimulation({
                simulationState: error ? SimulationState.ERROR : state,
                simulationProgress: (percentage && typeof +percentage === 'number') ? parseFloat(percentage) : 0
            });
        } catch (error) {
            console.error('Replan. sseSimulationMsgHandler error:', error);
        }

    }, [setSimulation])

    const sseLayersMsgHandler = useCallback((event) => {
        try {
            const data = JSON.parse(event.data);
            const {runId, layerId, state} = data;

            if (layerId && state) {
                dispatch(addedLayerSimulationState({layerId: layerId, calculationState: state}));
            }
        } catch (error) {
            console.error('Replan. sseLayersMsgHandler error:', error);
        }

    }, [addedLayerSimulationState, dispatch])

    const sseErrorHandler = useCallback((event) => {
        console.error('Replan. sseErrorHandler:', event);
        closeSseConnection();

        // if (sseConnectionAttemptsRef.current < 5) {
        addSseConnection(selectedRunId);
        sseConnectionAttemptsRef.current += 1;
        // }

        // setSimulation({simulationState: SimulationState.ERROR, simulationProgress: simulation.simulationProgress});
    }, [simulation, selectedRunId])

    useEffect(() => {
        if (selectedRunId) {
            closeSseConnection();
            addSseConnection(selectedRunId);
        }

        return () => {
            if (selectedRunId) {
                closeSseConnection();
            }
        };
    }, [selectedRunId])

    function addSseConnection(selectedRunId: string) {
        if (!sseConnectionRef.current && selectedRunId) {
            sseConnectionRef.current = new EventSource(`${ENV.REACT_APP_API_HOST}${ENV.REACT_APP_SSE_POINT}replan-run-sse-sim-stream/${selectedRunId}`);
            sseConnectionRef.current.addEventListener('message', sseSimulationMsgHandler);
            sseConnectionRef.current.addEventListener('error', sseErrorHandler);
        }
        if (!sseLayersConnectionRef.current && selectedRunId) {
            sseLayersConnectionRef.current = new EventSource(`${ENV.REACT_APP_API_HOST}${ENV.REACT_APP_SSE_POINT}replan-layer-sse-stream/${selectedRunId}`);
            sseLayersConnectionRef.current.addEventListener('message', sseLayersMsgHandler);
            sseLayersConnectionRef.current.addEventListener('error', sseErrorHandler);
        }
    }

    function closeSseConnection() {
        if (sseConnectionRef.current) {
            sseConnectionRef.current.removeEventListener('message', sseSimulationMsgHandler);
            sseConnectionRef.current.removeEventListener('error', sseErrorHandler);
            sseConnectionRef.current.close();
            sseConnectionRef.current = null;
        }
        if (sseLayersConnectionRef.current) {
            sseLayersConnectionRef.current.removeEventListener('message', sseLayersMsgHandler);
            sseLayersConnectionRef.current.removeEventListener('error', sseErrorHandler);
            sseLayersConnectionRef.current.close();
            sseLayersConnectionRef.current = null;
        }
    }

    async function startSimulation(data: any) {
        const resp = await projectsApi.startSimulation(data);

        if (!isErrorResponse(resp)) {
            const {id} = resp;
            selectRun(id);
            simulationStarted.current = true;
        }

        await simulationCompletedFn?.();

        return resp;
    }

    const restartSimulation = useCallback(async () => {
        const resp = await projectsApi.restartSimulation({runId: selectedRunId});
        await loadRuns();
        return resp;
    }, [closeSseConnection, addSseConnection, selectedRunId])

    async function getSimulationOptions(): Promise<ProjectRunProcessView> {
        const resp: ProjectRunProcessView = await entityApi.getEntity({
            entityId: projectId,
            entityName,
            params: {view: 'project-view_for_run_process'},
            abortSignal: abortControllerRef.current.signal
        });

        return resp;
    }

    return {simulation, startSimulation, restartSimulation, getSimulationOptions};
}

export default useSimulationStateLoader;