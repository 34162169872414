import React, {useCallback} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import EntitiesList from "../../../../components/Entities/EntitiesList/EntitiesList";
import StopEntityRowForm from "../Stops/StopEntityRowForm";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteProfileStopEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import AddStopToRoute from "./AddStopToRoute";
import {useEntitiesLoader} from "../../../../hooks/entites/useEntitiesLoader";
import './transit-route-stops-list.scss';
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {EntityServiceName} from "../../../../api/enums/enums";
import {FeatureProperties} from "../../../../redux/map/types";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import {getFeatureCenter} from "../../../../utils/mapUtils";
import {TransitRouteContext} from "../../types";
import EntitiesControls from "../../../../components/Entities/EntitiesControls/EntitiesControls";
import {flewToPoint, requireToHighlightFeatures, setZoom} from "../../../../redux/map/map-reducer";
import {publicTransitApi} from "../../../../api/publicTransitApi";
import {isErrorResponse} from "../../../../utils/utils";
import {useToastContext} from "../../../../context/toastContext";
import {useTranslation} from "react-i18next";


const TransitRouteStopsList = () => {
    const routeProfileStopsEntityName = PublicTransitLayerEntityName.ROUTE_STOPS;
    const stopsEntityName = PublicTransitLayerEntityName.STOPS;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;
    const stopsLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, stopsEntityName));
    const {deleteFn} = useEntityLoader<PublicTransitRouteProfileStopEntity>({entityName: routeProfileStopsEntityName});
    const {routeId} = useParams();

    const updateStopsOrder = useCallback(async (entities: PublicTransitRouteProfileStopEntity[]) => {
        const abortController = new AbortController();
        const resp = await publicTransitApi.rearrangeProfileStops({
                transitRouteId: routeId!,
                profileStopsOrder: entities.map(({id}) => id)
            },
            abortController.signal
        );

        if (isErrorResponse(resp)) {
            addToast(t("common.save-error"), "error");
        } else {
            addToast(t("common.save-successful"), "success");
        }
    }, [routeId])

    const {
        loading: stopsLoading,
        entities: routeStops,
        loadData: loadStopsData,
        loadFeatureOnRowClick,
        searchPhrase,
        setSearch,
        updateEntitiesOrder
    } = useEntitiesLoader<PublicTransitRouteProfileStopEntity>({
        entityName: routeProfileStopsEntityName,
        serviceName: serviceName,
        parentId: routeId,
        updateListOrderFn: updateStopsOrder
    });
    const {routeProfileId, loadRoute} = useOutletContext<TransitRouteContext>();
    const dispatch = useDispatch();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    //TODO here were clearedSelectedMapFeatures and enableLayersInteractivity called

    const onStopAddOrDelFn = useCallback(async (reloadLayer = false) => {
        await loadStopsData();
        await loadRoute(reloadLayer);
    }, [loadStopsData, loadRoute])

    const deleteRouteStop = useCallback(async ({entity}: { entity: PublicTransitRouteProfileStopEntity }) => {
        await deleteFn({entity});
        await onStopAddOrDelFn(true);
    }, [loadStopsData, loadRoute, deleteFn, onStopAddOrDelFn])

    const rowClickHandler = useCallback(async (entity: PublicTransitRouteProfileStopEntity) => {
        // await onRowClick(entity);

        const stopFeature = JSON.parse(entity?.transitStop?.geometryJson);

        const [lng, lat] = getFeatureCenter(stopFeature);
        dispatch(flewToPoint({lng, lat}));

        //TODO stops already highlighted. How to highlight clicked stop in addition?
        const {properties: {id, featureStateId}} = stopFeature as GeoJSON.Feature<GeoJSON.Point, FeatureProperties>;

        dispatch(requireToHighlightFeatures({
            featureProperties: {
                id,
                featureStateId: featureStateId.toString(),
                layerId: stopsLayerId,
                entityName: stopsEntityName
            }
        }));

        dispatch(setZoom(15));
    }, [stopsLayerId, stopsEntityName])

    return (
        <>
            <div className="transit-route-stops-controls">
                <EntitiesControls
                    searchPhrase={searchPhrase}
                    onSearch={setSearch}
                    showNewButton={false}
                    controls={
                        <AddStopToRoute
                            routeProfileId={routeProfileId}
                            onStopAdditionFn={onStopAddOrDelFn}
                        />
                    }
                />
            </div>
            <EntitiesList<PublicTransitRouteProfileStopEntity>
                loading={stopsLoading}
                entities={routeStops}
                form={StopEntityRowForm}
                onRowClick={rowClickHandler}
                // getUrlFunc={getUrlFunc}
                deleteFn={deleteRouteStop}
                loadDataFn={loadStopsData}
                hasLink={false}
                draggable={true}
                onUpdateListOrderFn={updateEntitiesOrder}
            />
        </>
    )
}

export default TransitRouteStopsList;