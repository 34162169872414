import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectDrawnFeatures, selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";
import {FeatureProperties} from "../../../../redux/map/types";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";


const RoadSegmentEdit = () => {
    const entityName = 'replancity_RoadInfrastructureSegment';
    const {roadSegmentId, roadId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));

    const saveFnArgs = useCallback((entity: any) => ({
        roadInfrastructureRoad: {id: roadId}
    }), [roadId]);

    const drawnFeatures = useTypedSelector(selectDrawnFeatures) as GeoJSON.Feature<any, Partial<FeatureProperties>>[];
    const {saveFn} = useEntityLoader({entityName, saveFnArgs, presetProperty: 'roadInfrastructurePreset'});

    const onSaveFn = useCallback(async (entity: any) => {
        //TODO this is a workaround to save 2nd segment after cut. Ideally should be improved
        if (drawnFeatures.length > 1) {
            const abortController = new AbortController();
            const {id, ...newEntity} = entity;
            newEntity['geometryJson'] = JSON.stringify(drawnFeatures?.[1]);
            await saveFn({entityName, data: newEntity, abortSignal: abortController.signal});
        }

        if (!roadSegmentId) {
            return `../${entity.id}/edit`;
        }
    }, [roadSegmentId, drawnFeatures]);

    const onDeleteFn = useCallback((entity: any) => '../../', []);

    return (
        <EntityManagerCleaner entityName={entityName}>
            <EntityEditor
                entityId={roadSegmentId!}
                entityName={entityName}
                layerId={sourceId}
                saveFnArgs={saveFnArgs}
                presetProperty={'roadInfrastructurePreset'}
                undoLastActionBtn={true}
                getUrlOnSaveFn={onSaveFn}
                getUrlOnDeleteFn={onDeleteFn}
            />
        </EntityManagerCleaner>
    )
}

export default RoadSegmentEdit;