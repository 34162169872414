import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
} from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import "./text-field.scss";
import {FormFieldProps} from "../Form/Form";
import {FieldWithLabel} from "../../../hoc/withClass";
import {useTheme} from "../../../context/themeContext";
import FieldWithButton from "../FieldWithButton/FieldWithButton";
import classNames from "classnames";

type TextFieldState = {
  valid?: boolean;
  errorMsg: string;
};

interface TextFieldProps extends FormFieldProps {
  onErrorMessage?: string;
  name: string;
  type: "number" | "email" | "text" | "password" | "time";
  linkable?: boolean;
  validationRegex?: string;
}

const TextField = React.forwardRef(({
                                      name,
                                      type,
                                      value = '',
                                      linkable = false,
                                      onChange,
                                      onErrorMessage,
                                        testId,
                                        validationRegex,
                                      ...props
                                    }: TextFieldProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const [valueState, setValue] = useState(value);
  const [state, setState] = useState<TextFieldState>({
    valid: undefined,
    errorMsg: "",
  });
  const {theme} = useTheme();

  useEffect(() => {
    setValue(value);
  }, [value])

  function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    const {validationMessage, value} = event.target;
    setValue(value);
    validate(validationMessage);

    onChange?.({...event});
  }

  function onInvalid(event: FormEvent<HTMLInputElement>) {
    event.preventDefault();

    const {validationMessage} = event.target as any;
    validate(validationMessage);
  }

  function validate(validationMessage: string) {
    const isValid = validationMessage === "";
    setState({valid: isValid, errorMsg: validationMessage});
  }

  const className = classNames(
    'text-field-md',
    theme,
    linkable && 'text-field-md_link'
  );

  return (
    <div className="form-field-md">
      <input
        className={className}
        // onSubmitCapture={(e) => console.log("input.onSubmitCapture", e)}
        name={name}
        type={type}
        value={valueState}
        onInvalid={onInvalid}
        onChange={onChangeHandler}
        ref={ref}
        {...(validationRegex ? {'pattern': validationRegex} : {})}
        {...(testId ? {'data-testid': testId} : {})}
        {...props}
      />
      {/*<ValidationIcon {...state} />*/}
      <ErrorMessage msg={state.errorMsg}/>
    </div>
  );
});

const Labeled = FieldWithLabel(TextField);

const LabeledWithButton = FieldWithButton(Labeled);

export {Labeled, LabeledWithButton, TextField as Field};

export default TextField;
