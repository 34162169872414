import * as React from "react";
import {Route} from "react-router-dom";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const BiMode = React.lazy(() => import('./BiModePage'));

export const useBiDashboardsRoutes = () => {
    return (
        <>
            <Route path="dashboard" element={<LazyLoadingWrapper element={<BiMode/>}/>}/>
        </>
    )
}