import {Dispatch, useCallback, useEffect, useRef, useState} from "react";
import {useMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {projectsApi} from "../../api/projectsApi";
import {isErrorResponse} from "../../utils/utils";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectModes} from "../../redux/selectors/selectors";
import {addedProjectModes, AllowedProjectModes, DEFAULT_ALLOWED_MODES} from "../../redux/reducers/projectsReducer";
import {useToastContext} from "../../context/toastContext";
import {useTranslation} from "react-i18next";
import {ApiError} from "../../api/RestClient";


export const MAPPED_URL_TO_MODE: Record<string, keyof AllowedProjectModes> = {
  'layers': 'layers',
  'algorithms': 'runOptimization',
  'report': 'report',
  'dashboard': 'biDashboard',
  'network': 'networkEditor',
  'transit': 'transitScheduleEditor',
  'maas': 'sharedMobilityEditor',
  'config': 'advancedConfig',
  'runs': 'runs',
  'ev': 'evInfrastructureEditor',
  'scenario': 'scenarioPresetEditor',
  "dvInfrastructureEditor": 'dvInfrastructureEditor'
};

type ReturnType = {
  projectModesLoaded: boolean;
  setProjectModesLoaded: Dispatch<boolean>
  error: ApiError | undefined;
  isModeAllowed: boolean;
  allowedModes: AllowedProjectModes;
  loadProjectModes: (projectId: string) => Promise<void>;
};

export const DEFAULT_MODE = 'layers';

export const useAllowedModes = (): ReturnType => {
  const match = useMatch('/:projectId/:mode/*');
  const mode = match?.params?.mode ? match.params.mode! : DEFAULT_MODE;
  const [projectModesLoaded, setProjectModesLoaded] = useState<boolean>(false);
  const [error, setError] = useState<ApiError>();
  const [isModeAllowed, setIfModeAllowed] = useState<boolean>(false);
  const allowedModes = useTypedSelector(selectCurrentProjectModes);
  const abortControllerRef = useRef(new AbortController());
  const dispatch = useDispatch();
  const {addToast} = useToastContext();
  const {t} = useTranslation();

  //TODO add loaded flag to redux and load once
  const loadAllowedModes = useCallback(async (projectId: string) => {
    const resp = await projectsApi.getAllowedModes(projectId, abortControllerRef.current.signal);

    if (!isErrorResponse(resp)) {
      const {
        runOptimization,
        report,
        biDashboard,
        sharedMobilityEditor,
        networkEditor,
        transitScheduleEditor,
        advancedConfig,
        runs,
        evInfrastructureEditor,
        scenarioPresetEditor,
        dvInfrastructureEditor,
        gisV2Layers
      } = resp;

      dispatch(addedProjectModes({
        runOptimization,
        report,
        biDashboard,
        sharedMobilityEditor,
        networkEditor,
        transitScheduleEditor,
        advancedConfig: !!advancedConfig,
        runs,
        evInfrastructureEditor,
        scenarioPresetEditor,
        dvInfrastructureEditor,
        gisV2Layers
      }))
    } else {
      setError(resp);
      addToast(t('project.allowed-modes-download-error'), 'error');
    }

    setIfModeAllowed(
        mode === DEFAULT_MODE ||
        mode in MAPPED_URL_TO_MODE && (!isErrorResponse(resp) ? resp[MAPPED_URL_TO_MODE[mode]] : DEFAULT_ALLOWED_MODES[mode])
    );
    setProjectModesLoaded(true);
  }, [mode])

  useEffect(() => {
    return () => {
      dispatch(addedProjectModes());
    }
  }, []);

  return {
    projectModesLoaded,
    setProjectModesLoaded,
    error,
    allowedModes,
    isModeAllowed,
    loadProjectModes: loadAllowedModes
  }
}