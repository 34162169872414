import React from "react";
import {Navigate, Route} from "react-router-dom";
import TransitRouteStopsList from "./TransitManagement/TransitRouteStopsList/TransitRouteStopsList";
import TransitRouteEdit from "./TransitManagement/TransitRoutes/TransitRouteEdit";
import TransitRouteDeparturesList from "./TransitManagement/TransitRouteDeparturesList/TransitRouteDeparturesList";
import DepartureEdit from "./TransitManagement/Departures/DepartureEdit";
import TransitLinesList from "./TransitManagement/TransitLines/TransitLinesList";
import TransitLineEdit from "./TransitManagement/TransitLines/TransitLineEdit";
import TransitLine from "./TransitManagement/TransitLines/TransitLine";
import TransitRoutesList from "./TransitManagement/TransitRoutes/TransitRoutesList";
import TransitRoute from "./TransitManagement/TransitRoutes/TransitRoute";
import {useTransitStopsRoutes} from "./TransitManagement/TransitStops/Routes";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";
import BreadcrumbedComponent from "../../components/BreadcrumbedComponent/BreadcrumbedComponent";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";
import {PublicTransitLayerEntityName} from "../../api/entities/replancity_PublicTransitLine";


const PublicTransitEditor = React.lazy(() => import('./PublicTransitEditorModePage'));

export const useTransitRoutes = () => {
    return (
        <Route path="transit" element={<LazyLoadingWrapper element={<PublicTransitEditor/>}/>}>
            {useTransitStopsRoutes()}
            <Route index element={<Navigate to="lines" replace/>}/>
            <Route element={<BreadcrumbedComponent/>}>
                <Route
                    path="lines"
                    handle={{crumb: (data: any) => ({name: 'All lines', ...data})}}
                >
                    <Route index element={<TransitLinesList/>}/>
                    <Route path="new" element={<TransitLineEdit/>}/>
                    <Route
                        path=":lineId"
                        element={<TransitLine/>}
                        loader={({params}) => params}
                        handle={{
                            crumb: ({lineId}) => ({
                                label: 'Line',
                                id: lineId,
                                entityName: PublicTransitLayerEntityName.LINES
                            })
                        }}
                    >
                        <Route index element={<TransitRoutesList/>}/>
                        <Route path="edit" element={<TransitLineEdit/>}/>
                        <Route
                            path="new"
                            element={<TransitRouteEdit/>}
                            handle={{crumb: (data: any) => ({name: 'New route', ...data})}}
                        />
                        <Route path=":routeId"
                               element={<TransitRoute/>}
                               loader={({params}) => params}
                               handle={{
                                   crumb: ({routeId}) => ({
                                       label: 'Route',
                                       id: routeId,
                                       entityName: PublicTransitLayerEntityName.ROUTES
                                   })
                               }}
                        >
                            <Route index element={<TransitRouteStopsList/>}/>
                            <Route path=":editMode" element={<TransitRouteEdit/>}/>
                            <Route path="departures" element={<TransitRouteDeparturesList/>}/>
                            <Route path="departures/new" element={<DepartureEdit/>}/>
                            <Route path="departures/:departureId/edit" element={<DepartureEdit/>}/>
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route
                path='layers'
                element={
                    <LayersListWrapper>
                        <LayersTimeSelectors/>
                    </LayersListWrapper>
                }
            />
        </Route>
    )
}