import React, {useCallback, useEffect, useMemo} from "react";
import {
    Outlet,
    useParams
} from "react-router-dom";
import {useDispatch} from "react-redux";
import {isErrorResponse, isUuidString} from "../../../../utils/utils";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {EntityView} from "../../../../api/entityApi";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import {
    removedFeaturesHighlighting,
    requireToHighlightFeatures,
    setSourceReloadRequired
} from "../../../../redux/map/map-reducer";
import {clearedSelectedMapFeatures} from "../../../../redux/showInfo/showInfoReducer";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";
import {useMapFiltersContext} from "../../../../context/mapFiltersContext";
import mapApi from "../../../../api/mapApi";
import {EntityServiceName} from "../../../../api/enums/enums";


const TransitRoute = () => {
    const entityName = PublicTransitLayerEntityName.ROUTES;
    const stopsEntityName = PublicTransitLayerEntityName.STOPS;
    const entityView: EntityView = 'full';
    const dispatch = useDispatch();
    const routesLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, PublicTransitLayerEntityName.ROUTES));
    const stopsLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, stopsEntityName));

    const {routeId} = useParams<any>();
    const existingRouteId = useMemo(() => isUuidString(routeId) ? routeId : '', [routeId]);

    const {entity, loadData} = useEntityLoader<PublicTransitRouteEntity>({entityName});

    const {setMapFilter} = useMapFiltersContext();

    const {defaultRouteProfile, defaultSchedule} = entity;
    const {id: routeProfileId} = defaultRouteProfile ?? {};
    const {id: scheduleId} = defaultSchedule ?? {};

    useEffect(() => {
        return () => {
            dispatch(clearedSelectedMapFeatures());
            dispatch(removedFeaturesHighlighting());
            setMapFilter([]);
        }
    }, []);

    const loadRoute = useCallback(async (reloadLayer = false) => {
        await loadData({entityName, entityId: existingRouteId, entityView});

        //TODO remove when it's not required to reload after stop addition
        if (reloadLayer && routesLayerId) {
            dispatch(setSourceReloadRequired({sourceId: routesLayerId, isReloadRequired: true}));
        }
    }, [loadData, entityName, entityView, existingRouteId, routesLayerId]);

    useEffect(() => {
        (async function () {
            await loadRoute();

            if (existingRouteId && routesLayerId && stopsLayerId) {
                const abortController = new AbortController();

                const resp = await mapApi.getListRelatedFeatures({
                        sourceId: existingRouteId,
                        sourceEntityType: entityName,
                        targetEntityType: PublicTransitLayerEntityName.STOPS
                    },
                    EntityServiceName.PUBLIC_TRANSIT,
                    abortController.signal);

                if (!isErrorResponse(resp)) {
                    const {featureId, relatedFeatures} = resp;

                    // setMapFilter(["==", ['get', 'id'], routeId]);

                    const featureProperties = relatedFeatures.map(id => ({
                        id,
                        featureStateId: id,
                        layerId: stopsLayerId,
                        entityName: stopsEntityName
                    }));
                    featureProperties.push({
                        id: existingRouteId,
                        featureStateId: featureId.toString(),
                        layerId: routesLayerId,
                        entityName
                    })

                    dispatch(requireToHighlightFeatures({
                        featureProperties
                    }));
                }
            }
        }());
    }, [loadRoute, existingRouteId, entityName, routesLayerId, stopsLayerId, dispatch])

    return (
        <>
            <EntityManagerCleaner entityName={entityName}>
                <Outlet context={{routeProfileId, scheduleId, loadRoute}}/>
            </EntityManagerCleaner>
        </>
    )
}

export default TransitRoute;