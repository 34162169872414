import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectMapLayerIdByEntityName,
    selectViewPortCoordinates,
} from "../../redux/selectors/selectors";
import {isErrorResponse} from "../../utils/utils";
import {entityApi} from "../../api/entityApi";
import {ViewPortCoordinates} from "../../api/entities/local/Borders";
import {BaseEntity, BaseEntityNameLess} from "../../api/entities/BaseEntity";
import {useEntityLoader} from "./useEntityLoader";
import {useMapFeatureLoader} from "./useMapFeatureLoader";
import {usePresetsContext} from "../../context/presetsContext";
import {FeatureWithParent} from "../../redux/entity/entity-reducer";


type Props<T> = {
    entityName: string;
    serviceName: string;
    parentId?: string;
    updateListOrderFn?: (entities: T[]) => Promise<void>;
}

export type EntitiesLoaderReturnType<T extends BaseEntity | BaseEntityNameLess> = {
    loading: boolean;
    deleting: boolean;
    entities: T[];
    loadData: () => Promise<void>;
    deleteFn: ({entity}: { entity: T }) => Promise<any>;
    loadFeatureOnRowClick: (entity: T) => Promise<FeatureWithParent['feature']>;
    error: string | undefined;
    searchPhrase: string;
    setSearch: Dispatch<SetStateAction<string>>;
    updateEntitiesOrder: (entities: T[]) => Promise<void>;
}

export const useEntitiesLoader = <T extends BaseEntity | BaseEntityNameLess>({
                                                                                 entityName,
                                                                                 serviceName,
                                                                                 parentId,
                                                                                 updateListOrderFn
                                                                             }: Props<T>): EntitiesLoaderReturnType<T> => {
    const [loading, setLoading] = useState<boolean>(false);
    const [searchPhrase, setSearch] = useState<string>('');
    const [error, setError] = useState<string>();
    const [entities, setEntities] = useState<T[]>([]);
    const {selectedPresetId} = usePresetsContext();
    const entityLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const viewPortCoordinates = useTypedSelector<ViewPortCoordinates>(selectViewPortCoordinates);
    const {loading: deleting, deleteFn: deleteEntity} = useEntityLoader<T>({entityName});
    const {loadFeature} = useMapFeatureLoader({entityName});
    const abortControllerRef = useRef(new AbortController());

    const loadData = useCallback(async () => {
        const {minLat, maxLat, minLon, maxLon} = viewPortCoordinates ?? {};

        if (selectedPresetId && minLat && maxLat && minLon && maxLon) {
            setLoading(true);
            try {

                const entities = await entityApi.getLeftPanelEntitiesList<T>({
                    entityName,
                    presetId: selectedPresetId,
                    parentId,
                    serviceName,
                    viewPort: viewPortCoordinates,
                    searchPhrase,
                    abortSignal: abortControllerRef.current.signal
                });
                if (!isErrorResponse(entities)) {
                    setEntities(entities);
                }
            } catch (e: any) {
                console.error('useEntitiesLoader.loadData error:', e);
                setError(e);
            }
            setLoading(false);
        }
    }, [entityName, selectedPresetId, serviceName, setLoading, viewPortCoordinates, searchPhrase, parentId])

    const loadFeatureOnRowClick = useCallback(async ({id}: T) => {
        const {feature} = await loadFeature({entityId: id, entityName, findFeatureParent: !!parentId})
        return feature;
    }, [entityName, entityLayerId])

    const deleteFn = useCallback(async ({entity}: { entity: T }) => {
        await deleteEntity({entity});
        await loadData();
    }, [deleteEntity, loadData])

    const updateEntitiesOrder = useCallback(async (entities: T[]): Promise<void> => {
        setLoading(true);
        await updateListOrderFn?.(entities);
        setLoading(false);
    }, [updateListOrderFn])

    useEffect(() => {
        (async function () {
            const {minLat, maxLat, minLon, maxLon} = viewPortCoordinates ?? {};
            if (minLat && maxLat && minLon && maxLon) {
                await loadData();
            }
        })();
    }, [selectedPresetId, viewPortCoordinates, searchPhrase, parentId]);

    return {
        loading,
        deleting,
        entities,
        loadData,
        deleteFn,
        loadFeatureOnRowClick,
        error,
        searchPhrase,
        setSearch,
        updateEntitiesOrder
    };

};
